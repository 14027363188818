import { downloadCSV } from 'react-admin';
import jsonExport from 'jsonexport/dist';
import TrainerCompanyFunctions from './trainerCompanyFunctions';
import QualiopiCategories from './qualiopiCategories';
import { formatLocalisation } from '../utils/utils';

const exporter = (records, fetchRelatedRecords, dataProvider) => {
    const ids = records.map(record => record.id);

    return dataProvider.getTrainerDetailsForExport({ ids })
        .then((trainers) => {
            const results = trainers.map((t) => ({
                id: t.id,
                firstname: t.firstname,
                lastname: t.lastname,
                email: t.email,
                phone: t.phone,
                createdAt: t.createdAt,
                validatedAt: t.validatedAt,
                lastLoginAt: t.lastLoginAt,
                disabled: t.isDisabled,
                license: t.hasLicense ? 'Active' : 'Inactive',
                tag: t.tag,
                savoirFaire: t.profile?.experience.savoirFaire,
                values: t.profile?.experience.values,
                savoirEtre: t.profile?.experience.savoirEtre,
                degrees: t.profile?.education.degrees,
                certifications: t.profile?.education.certifications,
                domains: t.profile?.domains?.map((d) => d.name),
                localisation: t.profile?.localisation?.map((l) => formatLocalisation(l)),
                expertise: t.profile?.expertise?.map((e) => `${e.title} - ${e.description}`),
                presentation: t.profile?.presentation,
                referentHandicap: t.profile?.referentHandicap,
                video: t.profile?.video?.name,
                companyName: t.admin?.companyName,
                legalStructure: t.admin?.legalStructure,
                siretNumber: t.admin?.siretNumber,
                function: TrainerCompanyFunctions.translate(t.admin?.function),
                address: t.admin?.address,
                website: t.admin?.website,
                activityDeclarationNumber: t.admin?.activityDeclarationNumber,
                hasBpf: t.admin?.hasBpf,
                bpfDate: t.admin?.bpfDate,
                bpf: t.admin?.bpf,
                hasQualiopi: t.admin?.hasQualiopi,
                qualiopiValidityDate: t.admin?.qualiopiValidityDate,
                qualiopiActionCategory: QualiopiCategories.translate(t.admin?.qualiopiActionCategory),
                adminLocalisation: formatLocalisation(t.admin?.localisation),
                diplomas: t.admin?.teacherDocuments?.diplomas?.map((d) => d.name),
                certificationFiles: t.admin?.teacherDocuments?.certifications?.map((c) => c.name),
                resume: t.admin?.teacherDocuments?.resume?.name,
                linkedin: t.admin?.teacherDocuments?.linkedIn,
                kbis: t.admin?.companyDocuments?.kbis?.name,
                assuranceRcPro: t.admin?.companyDocuments?.assuranceRcPro?.name,
                urssaf: t.admin?.companyDocuments?.urssaf?.name,
                attestationSignedByEmployer: t.admin?.companyDocuments?.attestationSignedByEmployer?.name,
                companyAttestationSignedByPartner: t.admin?.companyDocuments?.attestationSignedByPartner?.name,
                declarationActivityOfTrainingPartner: t.admin?.certificationDocuments?.declarationActivityOfTrainingPartner?.name,
                qualiopi: t.admin?.certificationDocuments?.qualiopi?.name,
                certificationAttestationSignedByPartner: t.admin?.certificationDocuments?.attestationSignedByPartner?.name,
                otherFiles: t.admin?.otherFiles?.map((c) => c.name),
                trainings: t.trainingNames
            }));

            return jsonExport(results, {}, (err, csv) => {
                downloadCSV(csv, 'trainers');
            });
        });
};

export default exporter;
