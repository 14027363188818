import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { FieldTitle, InputHelperText, useDataProvider, useInput } from 'react-admin';
import { debounce } from '@mui/material/utils';
import { formatLocalisation } from '../utils/utils';

const LocalisationInput = (props) => {
    const dataProvider = useDataProvider();

    const {
        field,
        isRequired,
        fieldState: { error, invalid, isTouched },
        formState: { isSubmitted }
    } = useInput({
        ...props,
    });

    const [options, setOptions] = useState([]);
    const [value, setValue] = useState(field.value);
    const [inputValue, setInputValue] = useState();
    const [loading, setLoading] = useState(false);
    const [fetchError, setFetchError] = useState();

    const fetch = useMemo(
        () => (
            debounce((request, callback) => {
                setLoading(true);
                dataProvider.searchPlaces(request)
                    .then(({ data }) => callback(data))
                    .catch((err) => setFetchError(err));
            }, 400)
        ),
        [ dataProvider, setLoading ]
    );

    useEffect(() => {
        let active = true;

        if (inputValue === '' || inputValue == null) {
            let optionsToSet;

            if (value == null) {
                optionsToSet = [];
            } else if (Array.isArray(value)) {
                optionsToSet = value;
            } else {
                optionsToSet = [value];
            }

            setOptions(optionsToSet);

            return undefined;
        }
    
        fetch({ query: inputValue }, (results) => {
            setLoading(false);
            setFetchError(null);

            if (active) {
                let newOptions = [];
        
                if (value) {
                    newOptions = [value];
                }
        
                if (results) {
                    newOptions = [...newOptions, ...results];
                }

                setOptions(newOptions);
            }
        });
    
        return () => {
            active = false;
        };
    }, [value, inputValue, fetch]);

    const label = props.label ?? field.name;

    return (
        <Autocomplete
            {...field}
            sx={{ width: 400 }}
            getOptionLabel={(option) => typeof option === 'string' ? option : formatLocalisation(option)}
            options={options}
            multiple={props.multiple}
            autoComplete
            filterSelectedOptions
            value={value}
            loading={loading}
            noOptionsText='Start typing to search for places...'
            isOptionEqualToValue={(option, value) => Array.isArray(option) ? option.some((o) => o.id === value.id) : option.id === value.id}
            onChange={(event, newValue) => {
                field.onChange(newValue);
                setValue(newValue);
            }}
            onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={
                        <FieldTitle
                            label={label}
                            source={props.source}
                            resource={props.resource}
                            isRequired={isRequired}
                        />
                    }
                    error={!!fetchError || ((isTouched || isSubmitted) && invalid)}
                    helperText={
                        <InputHelperText
                            touched={isTouched || isSubmitted || fetchError}
                            error={error?.message || fetchError?.message}
                            helperText={props.helperText}
                        />
                    }
                    fullWidth />
            )}
        />
    );
};

LocalisationInput.propTypes = {
    multiple: PropTypes.bool,
    label: PropTypes.string,
    noOptionsText: PropTypes.string,
    source: PropTypes.string,
    resource: PropTypes.string,
    helperText: PropTypes.string
};

export default LocalisationInput;
