import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { BooleanField, Datagrid, ReferenceField, TextField, useListContext } from 'react-admin';
import CustomDateField from '../components/CustomDateField';
import RatingField from '../components/RatingField';

const TitleDiv = styled('div')(() => ({
    fontSize: '150%',
    fontWeight: 'bold',
    marginBottom: 2
}));

const TrainerFeedback = () => {
    const { data } = useListContext();

    if (data == null || data.length === 0) {
        return <div></div>;
    }

    return (
        <Stack sx={{ mb: 5 }}>
            <Stack direction='row' justifyContent='space-between'>
                <TitleDiv>Feedback</TitleDiv>
            </Stack>
            <Datagrid bulkActionButtons={false}>
                <CustomDateField source='createdAt' />
                <ReferenceField reference='trainings' source='training' link='show' >
                    <TextField source='title' />
                </ReferenceField>
                <RatingField source='programConformity' size='small' />
                <RatingField source='visioQuality' size='small' />
                <RatingField source='trainerExchange' size='small' />
                <RatingField source='service' size='small' />
                <BooleanField source='isInterestedInTraining' />
                <TextField source='comments' emptyText='-' />
            </Datagrid>
        </Stack>
    );
};

export default TrainerFeedback;
