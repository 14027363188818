import Chip from '@mui/material/Chip';
import { useRecordContext } from 'react-admin';

const TrainerStatusField = (props) => {
    const trainer = useRecordContext();

    if (trainer?.status == null) {
        return <span></span>;
    }

    switch (trainer.status) {
    case 'created':
        return <Chip label='Créé' color='default' {...props} />
    case 'submitted':
        return <Chip label='A valider' color='info' {...props} />
    case 'validated':
        return <Chip label='Validé' color='success' {...props} />
    default:
        return <Chip label='Inconnu' {...props} />
    }
};

export default TrainerStatusField;
