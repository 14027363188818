import { useEffect, useState } from 'react';
import { BooleanField, Labeled, ReferenceField, SaveButton, SelectField, TextField, TextInput, Toolbar, useResourceContext } from 'react-admin';
import CustomDateField from '../components/CustomDateField';
import { getCustomTableEdit } from '../components/CustomTableEdit';
import { getPlural } from '../utils/utils';
import { FollowUpStatus } from './connectionEnums';

const nonEditableFields = ['updatedAt', 'visio'];

const dataHandler = (fields, fieldName, fieldList, index) => {
    if (nonEditableFields.includes(fieldName)) {
        return true;
    }

    if (fieldName === 'notBillableReason') {
        const validator = (value, allValues) => {
            if (allValues.isBillable) {
                return undefined;
            }

            if (value == null || value.trim() === '') {
                return 'Required';
            }

            return undefined;
        };

        fieldList.push(
            <TextInput key={index} source={fieldName} validate={validator} />
        );

        return true;
    }

    if (fieldName === 'createdAt' || fieldName === 'visioDate') {
        fieldList.push(
            <Labeled key={index}>
                <CustomDateField source={fieldName} sx={{ mb: 2 }} />
            </Labeled>
        );

        return true;
    }

    if (fieldName === 'student' || fieldName === 'trainer') {
        fieldList.push(
            <Labeled key={index}>
                <ReferenceField source={fieldName} reference={getPlural(fieldName)} sx={{ mb: 2 }} link='show'>
                    <TextField source='name' />
                </ReferenceField>
            </Labeled>
        );

        return true;
    }

    if (fieldName === 'isTrainerConnected') {
        fieldList.push((
            <Labeled key={index} label='Formateur connecté'>
                <BooleanField source={fieldName} sx={{ mb: 2 }} />
            </Labeled>
        ));

        return true;
    }

    if (fieldName === 'isStudentConnected') {
        fieldList.push((
            <Labeled key={index} label='Apprenant connecté'>
                <BooleanField source={fieldName} sx={{ mb: 2 }} />
            </Labeled>
        ));

        return true;
    }

    if (fieldName === 'participantsJoinedTogether') {
        fieldList.push((
            <Labeled key={index} label='Connectés au même moment'>
                <BooleanField source={fieldName} sx={{ mb: 2 }} />
            </Labeled>
        ));

        return true;
    }

    if (fieldName === 'isCancelled') {
        fieldList.push((
            <Labeled key={index} label='Annulé'>
                <BooleanField source={fieldName} sx={{ mb: 2 }} />
            </Labeled>
        ));

        return true;
    }

    if (fieldName === 'cancelledReason') {
        fieldList.push((
            <Labeled key={index} label="Raison de l'annulation">
                <TextField key={index} source={fieldName} sx={{ mb: 2 }} />
            </Labeled>
        ));

        return true;
    }

    if (fieldName === 'followUpStatus') {
        fieldList.push((
            <Labeled key={index} label='Status'>
                <SelectField key={index} source={fieldName} sx={{ mb: 2 }} choices={[
                    { id: FollowUpStatus.NO_FOLLOWUP, name: 'Sans suite' },
                    { id: FollowUpStatus.VALIDATED, name: 'Validée' }
                ]} />
            </Labeled>
        ));

        return true;
    }

    return false;
};

const ConnectionEdit = (props) => {
    const resource = useResourceContext(props);
    const [data, setData] = useState();

    useEffect(() => {
        const formProps = {
            toolbar: (
                <Toolbar>
                    <SaveButton />
                </Toolbar>
            )
        };

        getCustomTableEdit(resource, props, dataHandler, { formProps }).then((data) => setData(data));
    }, [ props, resource ]);

    if (data == null) {
        return <div></div>;
    }

    return data;
};

export default ConnectionEdit;
