import {DateField, EmailField, ReferenceField, Show, SimpleShowLayout, TextField} from 'react-admin';
import RfiStatusField from '../components/RfiStatusField';

const RfiShow = () => (
    <div>
        <Show>
            <SimpleShowLayout>
                <RfiStatusField />
                <ReferenceField source="toTrainer" reference="trainers"/>
                <ReferenceField source="toTraining" reference="trainings" label="Training (if selected by visitor)"/>
                <TextField source="fromFirstname" label="Visitor Firstname"/>
                <TextField source="fromLastname" label="Lastname"/>
                <EmailField source="fromEmail" label="E-mail" />
                <TextField source="fromPhone" label="Phone"/>
                <TextField source="request" label="Content of Request" />
                <ReferenceField reference="form-orientations" source="fromFormOrientation" label="Funnel d'origine"/>
                <DateField source="createdAt"/>
                <DateField source="viewedAt"/>
            </SimpleShowLayout>
        </Show>
    </div>
);

export default RfiShow;