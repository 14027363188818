import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { Labeled, ReferenceField, SelectInput, TextField, TextInput, useResourceContext } from 'react-admin';
import { getCustomTableEdit, getFieldValidation } from '../components/CustomTableEdit';
import { LicenseRequestStatus } from './licenseRequestEnums';

const nonEditableFields = ['createdAt', 'updatedAt'];

const dataHandler = (fields, fieldName, fieldList, index) => {
    if (nonEditableFields.includes(fieldName)) {
        return true;
    }

    if (fieldName === 'trainer') {
        fieldList.push(
            <Labeled>
                <ReferenceField source={fieldName} reference='trainers' sx={{ mb: 2 }} link='show'>
                    <TextField source='name' />
                </ReferenceField>
            </Labeled>
        );

        return true;
    }

    if (fieldName === 'status') {
        const validate = getFieldValidation(fields, fieldName);

        fieldList.push(
            <SelectInput key={index} source='status' validate={validate} choices={
                Object.values(LicenseRequestStatus.values).map((value) => ({ id: value, name: LicenseRequestStatus.translate(value) }))
            } />
        );

        return true;
    }

    if (fieldName === 'address') {
        const validateLine1 = getFieldValidation(fields, 'address.children.line1');
        const validateLine2 = getFieldValidation(fields, 'address.children.line2');
        const validatePostalCode = getFieldValidation(fields, 'address.children.postalCode');
        const validateCity = getFieldValidation(fields, 'address.children.city');

        fieldList.push(
            <Box sx={{ maxWidth: '500px', mb: 2 }}>
                <Typography variant='caption' gutterBottom>
                    Address
                </Typography>
                <TextInput source='address.line1' fullWidth helperText={false} validate={validateLine1} />
                <TextInput source='address.line2' fullWidth helperText={false} validate={validateLine2} />
                <Box display={{ xs: 'block', sm: 'flex' }}>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                        <TextInput source='address.postalCode' fullWidth helperText={false} validate={validatePostalCode} />
                    </Box>
                    <Box flex={1}>
                        <TextInput source='address.city' fullWidth helperText={false} validate={validateCity} />
                    </Box>
                </Box>
            </Box>
        );

        return true;
    }

    return false;
};

const LicenseRequestEdit = (props) => {
    const resource = useResourceContext(props);
    const [data, setData] = useState();

    useEffect(() => {
        const allProps = {
            ...props,
            redirect(resource, id, data) {
                if (data.status === LicenseRequestStatus.values.PAYED) {
                    return `trainers/${data.trainer}#license`;
                }

                return resource;
            }
        }
        getCustomTableEdit(resource, allProps, dataHandler).then((data) => setData(data));
    }, [ props, resource ]);

    if (data == null) {
        return <div></div>;
    }

    return data;
};

export default LicenseRequestEdit;
