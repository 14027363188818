import { useEffect, useState } from 'react';
import {
    EditButton,
    ListButton,
    TopToolbar,
    useResourceContext
} from 'react-admin';
import { getCustomTableShow } from '../components/CustomTableShow';
import Feedback from './TrainingFeedback';
import dataHandler from './TrainingShowDataHandler';


const PostShowActions = () => (
    <TopToolbar>
        <ListButton />
        <EditButton />
    </TopToolbar>
);

const TrainingShow = (props) => {
    const resource = useResourceContext(props);
    const [data, setData] = useState();

    useEffect(() => {
        const propsToUse = {
            ...props,
            actions: <PostShowActions />
        };

        const extraFields = [
            <Feedback key={'feedback_0'} />
        ]

        getCustomTableShow(resource, propsToUse, dataHandler, { extraFields }).then((data) => setData(data));
    }, [ props, resource ]);

    if (data == null) {
        return <div></div>;
    }

    return data;
};

export default TrainingShow;
