import {
    Datagrid,
    DateField,
    DateTimeInput,
    List,
    NumberField,
    ReferenceField,
    ReferenceInput,
    TextField
} from 'react-admin';

const TransactionList = () => {

    const postFilters = [
        <ReferenceInput key='trainer_list' source="trainer" reference="trainers" label="Trainer" alwaysOn />,
        <DateTimeInput key='createdAt_gte' source='createdAt_gte' label='Created after' alwaysOn />,
        <DateTimeInput key='createdAt_lte' source='createdAt_lte' label='Created before' alwaysOn />,
    ];

    return (
        <List filters={postFilters} sort={{ field: 'createdAt', order: 'DESC' }}>
            <Datagrid rowClick="show">
                <ReferenceField source="trainer" reference="trainers"/>
                <DateField source="createdAt"/>
                <TextField source="status"/>
                <NumberField source="credits"/>
                <TextField source="type"/>
                <ReferenceField source="rfi" reference="rfis" label="Request for info" />
                <ReferenceField source="connection" reference="connections" label="Visio" />
            </Datagrid>
        </List>
    );
};

export default TransactionList;