import {Datagrid, List, TextField} from 'react-admin';


const DomainList = () => (
    <List exporter={false} sort={{ field: 'name', order: 'ASC' }}>
        <Datagrid rowClick="edit">
            <TextField source="name" />
            <TextField source="picture" />
        </Datagrid>
    </List>
);

export default DomainList;