import PropTypes from 'prop-types';
import get from 'lodash.get';
import { useListContext } from 'react-admin';
import { styled } from '@mui/material/styles';

const StyledLi = styled('li')(({ theme }) => ({
    fontSize: theme.typography.body2.fontSize
}));

const BulletList = ({ source, formatter, ...props }) => {
    const { data: list } = useListContext(props);
    
    if (list == null) {
        return <span>-</span>;
    }

    const itemFormatter = formatter ?? ((x) => source == null ? x : get(x, source));

    return (
        <ul style={{ marginBlock: 0 }}>
            {list.map((x, index) => (
                <StyledLi key={index}>{itemFormatter(x)}</StyledLi>
            ))}
        </ul>
    );
};

BulletList.propTypes = {
    source: PropTypes.string,
    formatter: PropTypes.func
};

export default BulletList;
