import {useState} from 'react';
import {
    Button, Confirm,
    DateField,
    NumberField,
    ReferenceField,
    Show,
    SimpleShowLayout,
    TextField,
    TopToolbar,
    useDataProvider,
    useNotify,
    useRecordContext,
    useRefresh,
} from 'react-admin';



const TransactionCancelButton = () => {
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const notify = useNotify();
    const [open, setOpen] = useState(false);

    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);


    const handleConfirm = () => {
        console.log('Confirmation annulation de la transaction', record);
        // Use of CREATE method to launch a POST request
        dataProvider.create('transactions/cancel', { data: {id: record.id, status: 'cancelled' } })
            .then(() => {
                setOpen(false);
                refresh();
                notify('Transaction cancelled', 'info');
            })
            .catch((e) => {
                notify('An error occurred while cancelling the transaction.', 'warning');
            });
    };

    return (
        <>
            <Button label="Cancel transaction" style={{color: 'red'}} onClick={handleClick} />
            <Confirm
                isOpen={open}
                title={`Cancel transaction #${record && record.id}`}
                content="Are you sure you want to cancel this transaction? All the credits will be refunded to the trainer."
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </>
    );
}

const TransactionShowActions = () => (
    <TopToolbar>
        <TransactionCancelButton />
    </TopToolbar>
);
const TransactionShow = () => (
    <Show actions={<TransactionShowActions />}>
        <SimpleShowLayout>
            <ReferenceField source="trainer" reference="trainers" />
            <DateField source="createdAt" />
            <NumberField source="credits" />
            <TextField source="status" />
            <TextField source="type" />
            <ReferenceField source="rfi" reference="rfis" label="Request for info" />
            <ReferenceField source="connection" reference="connections" label="Visio" />
        </SimpleShowLayout>
    </Show>
);

export default TransactionShow;