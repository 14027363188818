import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { Link, useCreatePath, useDataProvider } from 'react-admin';
import { formatDateLong, formatDateRelative } from '../dateTimeHelpers';
import { getFileUri } from '../helpers';

const getDateTimeString = (dateTime) => {
    const date = formatDateLong(dateTime);
    const relativeDate = formatDateRelative(dateTime);

    return <Tooltip title={date}><span>{relativeDate}</span></Tooltip>;
};

const Trainers = ({ trainers }) => {
    const createPath = useCreatePath();

    if (trainers.length === 0) {
        return <div>Aucun profil à valider.</div>
    }

    return (
        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            {trainers.map((trainer) => (
                <ListItem key={trainer.id}>
                    <ListItemAvatar>
                        <Avatar alt={trainer.name} src={getFileUri(trainer.picture, 'trainer/picture')}>
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={trainer.name}
                        secondary={getDateTimeString(trainer.submittedAt)}
                    />
                    <Link to={createPath({ resource: 'trainers', type: 'show', id: trainer.id })} variant="button">Valider...</Link>
                </ListItem>
            ))}
        </List>
    );
};

Trainers.propTypes = {
    trainers: PropTypes.array
};

const DashboardTrainersToValidate = () => {
    const dataProvider = useDataProvider();
    const [trainers, setTrainers] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        dataProvider.getLatestTrainersToValidate()
            .then((data) => {
                setTrainers(data.trainers);
                setLoading(false);
            })
    }, [
        dataProvider
    ]);

    const content = loading
        ? (
            <Stack direction='row' width={360} alignItems='center'>
                <Skeleton variant="circular">
                    <Avatar />
                </Skeleton>
                <Skeleton width={300} height={30} sx={{ marginLeft: 1 }}></Skeleton>
            </Stack>
        )
        : <Trainers trainers={trainers} />;

    return (
        <div>
            <Typography fontSize={'120%'} fontWeight="bold">Formateurs à valider</Typography>
            {content}
        </div>
    );
};

export default DashboardTrainersToValidate;
