import PropTypes from 'prop-types';
import Rating from '@mui/material/Rating';
import { useRecordContext } from 'react-admin';
import get from 'lodash.get';

const RatingField = ({ source, ...rest }) => {
    const record = useRecordContext(rest);

    const value = get(record, source);

    return (
        <Rating {...rest} value={value} readOnly />
    );
};

RatingField.propTypes = {
    source: PropTypes.string
};

export default RatingField;
