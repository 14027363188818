import { BooleanField, Datagrid, DateTimeInput, EditButton, List, SearchInput, ShowButton, TextField } from 'react-admin';
import CustomDateField from '../components/CustomDateField';
import exporter from './studentExporter';

const StudentList = () => {
    const postFilters = [
        <SearchInput key='Search' source='q' alwaysOn />,
        <DateTimeInput key='createdAt_gte' source='createdAt_gte' label='Created after' />,
        <DateTimeInput key='createdAt_lte' source='createdAt_lte' label='Created before' />,
        <DateTimeInput key='lastLoginAt_gte' source='lastLoginAt_gte' label='Last login after' />,
        <DateTimeInput key='lastLoginAt_lte' source='lastLoginAt_lte' label='Last login before' />
    ];

    return (
        <List exporter={exporter} filters={postFilters} filter={{ deletedAt: null }} sort={{ field: 'createdAt', order: 'DESC' }}>
            <Datagrid>
                <TextField source='firstname' />
                <TextField source='lastname' />
                <TextField source='email' />
                <TextField source='phone' />
                <CustomDateField source='lastLoginAt' />
                <CustomDateField source='createdAt' />
                <BooleanField source='isDisabled' />
                <ShowButton />
                <EditButton />
            </Datagrid>
        </List>
    );
};

export default StudentList;
