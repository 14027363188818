import Box from '@mui/material/Box'
import CircleIcon from '@mui/icons-material/Circle';
import Typography from '@mui/material/Typography'
import { useEffect, useState } from 'react';
import { DateTimeInput, SelectInput, TextInput, required, useResourceContext } from 'react-admin';
import { getCustomTableEdit } from '../components/CustomTableEdit';
import TrainerLicenseField from '../components/TrainerLicenseField';
import { TrainerTags } from './trainerTags';

const nonEditableFields = [
    'status',
    'createdAt',
    'updatedAt',
    'deletedAt',
    'lastLoginAt',
    'submittedAt',
    'validatedAt',
    'isValidationAcknowledged',
    'stripeId',
    'licenseStartDate',
    'licenseEndDate'
];

const dataHandler = (fields, fieldName, fieldList, index) => {
    if (nonEditableFields.includes(fieldName)) {
        return true;
    }

    if (fieldName === 'password') {
        fieldList.push(<TextInput key={index} source={fieldName} type='password' />)
        return true;
    }

    if (fieldName === 'sponsorshipCode') {
        fieldList.push(
            <TextInput key={index} source={fieldName} disabled />
        );

        return true;
    }

    if (fieldName === 'tag') {
        fieldList.push(
            <SelectInput key={index} source={fieldName}
                validate={required()}
                choices={
                    TrainerTags.tags.map(({ name, backgroundColor }) => ({ id: name, name, backgroundColor }))
                }
                optionText={({ name, backgroundColor }) => (
                    <Typography my={0.5}>
                        <CircleIcon sx={{ color: backgroundColor, pr: 1, verticalAlign: 'middle' }} />{name}
                    </Typography>
                )}
            />
        );

        return true;
    }

    return false;
};

const TrainerEdit = (props) => {
    const resource = useResourceContext(props);
    const [data, setData] = useState();

    const validateStartDate = (value, allValues) => {
        console.log(value, allValues.licenseEndDate);
        if (allValues.licenseEndDate == null) {
            return undefined;
        }

        if (value == null) {
            return 'Required';
        }

        const startDate = new Date(value);
        const endDate = new Date(allValues.licenseEndDate);

        if (startDate >= endDate) {
            return 'Must be before end date';
        }

        return undefined;
    };

    const validateEndDate = (value, allValues) => {
        if (allValues.licenseStartDate == null) {
            return undefined;
        }

        if (value == null) {
            return 'Required';
        }

        const startDate = new Date(allValues.licenseStartDate);
        const endDate = new Date(value);

        if (endDate <= startDate) {
            return 'Must be after start date';
        }

        return undefined;
    };

    useEffect(() => {
        const extraFields = [
            <div key='licensing'>
                <Typography variant="h6" gutterBottom id='license'>
                    License
                    <TrainerLicenseField size='small' sx={{ ml: 1 }} />
                </Typography>
                <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                        <DateTimeInput source='licenseStartDate' label='Start date' validate={validateStartDate} />
                    </Box>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                        <DateTimeInput source='licenseEndDate' label='End date' validate={validateEndDate} />
                    </Box>
                </Box>
            </div>
        ];

        getCustomTableEdit(resource, props, dataHandler, { extraFields }).then((data) => setData(data));
    }, [ props, resource ]);

    if (data == null) {
        return <div></div>;
    }

    return data;
};

export default TrainerEdit;
