import PropTypes from 'prop-types';
import get from 'lodash.get';
import { useRecordContext } from 'react-admin';
import { getFileUri } from '../helpers';

export default function VideoField({ source }) {
    const record = useRecordContext();

    if (record == null) {
        return <span>-</span>;
    }

    const item = get(record, source);
    const videoUri = getFileUri(item);

    if (videoUri == null) {
        return <span>-</span>;
    }

    return (
        <video src={videoUri} height={243} width={432} controls></video>
    );
};

VideoField.propTypes = {
    source: PropTypes.string
};
