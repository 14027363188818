import { useEffect, useState } from 'react';
import { EditButton, ListButton, SelectField, TopToolbar, useResourceContext } from 'react-admin';
import { getCustomTableShow } from '../components/CustomTableShow';
import { TrainingFormats } from '../trainings/trainingEnums';
import { StudentBudget, StudentExperience, StudentTrainingType, StudentStatus } from './studentEnums';
import BulletListField from '../components/BulletListField';

const dataHandler = (fields, fieldName, fieldList, index) => {
    if (fieldName === 'password' || fieldName === 'deletedAt') {
        return true;
    }

    if (fieldName === 'status') {
        fieldList.push(
            <SelectField key={index} source={fieldName} choices={
                Object.values(StudentStatus.values).map((value) => ({ id: value, name: StudentStatus.translate(value) }))
            } />
        );

        return true;
    }

    if (fieldName === 'trainingType') {
        fieldList.push(
            <SelectField key={index} source={fieldName} choices={
                Object.values(StudentTrainingType.values).map((value) => ({ id: value, name: StudentTrainingType.translate(value) }))
            } />
        );

        return true;
    }

    if (fieldName === 'yearlyBudget') {
        fieldList.push(
            <SelectField key={index} source={fieldName} choices={
                Object.values(StudentBudget.values).map((value) => ({ id: value, name: StudentBudget.translate(value) }))
            } />
        );

        return true;
    }

    if (fieldName === 'experience') {
        fieldList.push(
            <SelectField key={index} source={fieldName} choices={
                Object.values(StudentExperience.values).map((value) => ({ id: value, name: StudentExperience.translate(value) }))
            } />
        );

        return true;
    }

    if (fieldName === 'preferredFormat') {
        fieldList.push(
            <BulletListField key={index} source={fieldName} formatter={(value) => TrainingFormats.translate(value)} />
        );

        return true;
    }

    return false;
};

const PostShowActions = () => (
    <TopToolbar>
        <ListButton />
        <EditButton />
    </TopToolbar>
);

const StudentShow = (props) => {
    const resource = useResourceContext(props);
    const [data, setData] = useState();

    useEffect(() => {
        const propsToUse = {
            ...props,
            actions: <PostShowActions />
        };

        getCustomTableShow(resource, propsToUse, dataHandler).then((data) => setData(data));
    }, [ props, resource ]);

    if (data == null) {
        return <div></div>;
    }

    return data;
};

export default StudentShow;
