import { stringify } from 'query-string';
import { fetchUtils } from 'react-admin';
import { LicenseRequestStatus } from './license-request/licenseRequestEnums';

const apiUrl = process.env.REACT_APP_API_URL;

const flatten = (obj) => {
    if (obj == null) {
        return null;
    }

    if ((typeof obj) !== 'object' || Array.isArray(obj)) {
        return obj;
    }

    const result = {};

    for (const [key, value] of Object.entries(obj)) {
        if (value == null) {
            result[key] = value;
        } else if ((typeof value) === 'object' && !Array.isArray(value)) {
            const childObj = flatten(value);

            for (const [childKey, childValue] of Object.entries(childObj)) {
                result[`${key}.${childKey}`] = childValue;
            }
        } else {
            result[key] = value;
        }
    }

    return result;
};

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const token = localStorage.getItem('token');
    options.headers.set('Authorization', `JWT ${token}`);
    return fetchUtils.fetchJson(url, options);
};

const dataProvider = {
    getList: (resource, params) => {
        const filter = params.filter;

        if (resource === 'students' || resource === 'trainers') {
            filter.deletedAt = null;
        }

        const operators = { '_gte': '$gte', '_lte': '$lte', '_neq': '$ne' };
        const filters = flatten(filter);

        Object.entries(filters).forEach(([key, value]) => {
            const operator = operators[key.slice(-4)];

            if (operator == null) {
                filters[key] = value;
            } else {
                const slicedKey = key.slice(0, -4);

                if (filters[slicedKey] == null) {
                    filters[slicedKey] = { [operator]: value };
                } else {
                    filters[slicedKey][operator] = value;
                }

                delete filters[key];
            }
        });

        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify(filters)
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;


        return httpClient(url).then(({ headers, json }) => (
            {
                data: json,
                total: parseInt(headers.get('Content-Range').split('/').pop(), 10)
            }
        ));
    },

    getOne: (resource, params) => (
        httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => {
            return { data: json };
        })
    ),

    getMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids })
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        return httpClient(url).then(({ json }) => ({ data: json }));
    },

    getManyReference: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id
            })
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => ({
            data: json,
            total: parseInt(headers.get('Content-Range').split('/').pop(), 10)
        }
        ));
    },

    getSchema: (resource) => {

        const url = `${apiUrl}/${resource}/schema`;

        return httpClient(url).then(({ headers, json }) => ({
            data: json
            // total: parseInt(headers.get('Content-Range').split('/').pop(), 10),
        }
        ));
    },

    update: (resource, params) => (
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'PUT',
            body: JSON.stringify(params.data)
        }).then(({ json }) => ({ data: json }))
    ),

    updateMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids })
        };
        return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
            method: 'PUT',
            body: JSON.stringify(params.data)
        }).then(({ json }) => ({ data: json }));
    },

    create: (resource, params) => {
        return httpClient(`${apiUrl}/${resource}`, {
            method: 'POST',
            body: JSON.stringify(params.data)
        }).then(({ json }) => ({
            data: { ...params.data, id: json.id }
        }));

    },

    delete: (resource, params) => (
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'DELETE'
        }).then(({ json }) => ({ data: json }))
    ),

    deleteMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids })
        };
        return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
            method: 'DELETE',
            body: JSON.stringify(params.data)
        }).then(({ json }) => ({ data: [json] }));
    },

    getDashboardMetrics: () => (
        httpClient(`${apiUrl}/dashboard/metrics`, { method: 'GET' })
            .then(({ json }) => ({ metrics: json }))
    ),

    searchPlaces: ({ query }) => {
        const queryString = { q: query };
        return httpClient(`${apiUrl}/places?${stringify(queryString)}`, { method: 'GET' }).then(({ json }) => ({ data: json }));
    },

    getLatestTrainersToValidate: () => {
        const query = {
            filter: JSON.stringify({ status: 'submitted', deletedAt: null }),
            sort: JSON.stringify(['submittedAt', 'DESC']),
            limit: 5
        };

        return httpClient(`${apiUrl}/trainers?${stringify(query)}`, { method: 'GET' })
            .then(({ json }) => ({ trainers: json }));
    },

    getLatestLicenseRequests: () => {
        const query = {
            filter: JSON.stringify({ status: LicenseRequestStatus.values.NOT_TREATED }),
            sort: JSON.stringify(['createdAt', 'DESC'])
        };

        return httpClient(`${apiUrl}/license-requests?${stringify(query)}`, { method: 'GET' })
            .then(({ json }) => ({ licenseRequests: json }));
    },

    validateTrainer: (params) => (
        httpClient(`${apiUrl}/trainers/validate/${params.id}`, { method: 'POST' }).then(({ json }) => json)
    ),

    unvalidateTrainer: (params) => (
        httpClient(`${apiUrl}/trainers/unvalidate/${params.id}`, { method: 'POST' }).then(({ json }) => json)
    ),

    getTrainerDetailsForExport: (params) => {
        return httpClient(`${apiUrl}/trainers/details`, {
            method: 'POST',
            body: JSON.stringify({ ids: params.ids })
        }).then(({ json }) => json);
    },

    getLicenseCost: () => (
        httpClient(`${apiUrl}/license-cost`, { method: 'GET' })
            .then(({ json }) => json)
    ),

    updateLicenseCost: (params) => (
        httpClient(`${apiUrl}/license-cost`, {
            method: 'POST',
            body: JSON.stringify(params.data)
        }).then(({ json }) => ({
            data: { ...params.data, id: json.id }
        }))
    ),

    uploadFiles: async (folder, fileInfos) => {
        const url = `${apiUrl}/files/${folder}`;

        const formData = new FormData();
        fileInfos.forEach((fileInfo) => formData.append('files', fileInfo));

        const token = localStorage.getItem('token');

        return httpClient(url, {
            method: 'POST',
            headers: new Headers(
                {
                    'Authorization': `JWT ${token}`
                }
            ),
            body: formData

        }).then(({ headers, json }) => ({
            data: json
        }));
    },

    createImpersonation: (params) => {
        let datas = {};
        if (params.trainer) {
            datas.trainer = params.trainer;
        }
        return httpClient(`${apiUrl}/impersonations`, {
            method: 'POST',
            body: JSON.stringify(datas)
        }).then(({ json }) => json);
    },
};

export default dataProvider;
