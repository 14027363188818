import { DateField } from 'react-admin';

const CustomDateField = (props) => {
    const dateOptions = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
    };

    return (
        <DateField options={dateOptions} {...props} locales='fr'></DateField>
    );
};

export default CustomDateField;
