import PropTypes from 'prop-types';
import get from 'lodash.get';
import { useRecordContext } from 'react-admin';
import { styled } from '@mui/material/styles';

const StyledLi = styled('li')(({ theme }) => ({
    fontSize: theme.typography.body2.fontSize
}));

const BulletListField = ({ source, formatter, ...props }) => {
    const record = useRecordContext(props);

    if (record == null) {
        return <span>-</span>;
    }

    const items = get(record, source);

    if (items == null || !Array.isArray(items) || items.length === 0) {
        return <span>-</span>;
    }

    const itemFormatter = formatter ?? ((x) => x);

    return (
        <ul style={{ marginBlock: 0 }}>
            {items.map((x, index) => (
                <StyledLi key={index}>{itemFormatter(x)}</StyledLi>
            ))}
        </ul>
    );
};

BulletListField.propTypes = {
    source: PropTypes.string,
    formatter: PropTypes.func
};

export default BulletListField;
