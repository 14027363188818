import { DateTime, Settings } from 'luxon';

Settings.defaultLocale = 'fr';

export const formatDateLong = (dateString) => {
    const date = DateTime.fromISO(dateString);
    return date.toLocaleString(DateTime.DATETIME_MED);
};

export const formatDateRelative = (dateString) => {
    const date = DateTime.fromISO(dateString);
    return date.toRelative();
};
