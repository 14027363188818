import get from 'lodash.get';
import Link from '@mui/material/Link';
import PropTypes from 'prop-types';
import { useRecordContext } from 'react-admin';
import { getFileUri } from '../helpers';

const CustomFileField = (props) => {
    const record = useRecordContext(props);

    if (record == null) {
        return <div>-</div>;
    }

    const item = props.source == null ? record : get(record, props.source);

    const fileUri = getFileUri(item);

    if (fileUri == null) {
        return <div>-</div>;
    }

    const title = get(item, 'name');

    return (
        <div style={{ display: 'inline-block' }}>
            <Link variant='body2' href={fileUri} target='_blank' {...props} title={title}>{title ?? fileUri}</Link>
        </div>
    );
};

CustomFileField.propTypes = {
    filetype: PropTypes.string,
    source: PropTypes.string
};

export default CustomFileField;
