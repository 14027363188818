import PropTypes from 'prop-types';
import InputAdornment from '@mui/material/InputAdornment';
import { NumberInput } from 'react-admin';

const PriceInput = ({ currency = 'EUR', ...props }) => {
    const numberFormat = new Intl.NumberFormat('fr-FR', { style: 'currency', currency });
    const parts = numberFormat.formatToParts(123456.789);

    const currencyIndex = parts.findIndex((p) => p.type === 'currency');
    const currencySign = currencyIndex >= 0 ? parts[currencyIndex].value : currency;

    const inputProps = {};

    if (currencyIndex > 0) {
        inputProps.endAdornment = <InputAdornment position='end'>{currencySign}</InputAdornment>;
    } else {
        inputProps.startAdornment = <InputAdornment position='start'>{currencySign}</InputAdornment>;
    }

    return (
        <NumberInput {...props} InputProps={inputProps} />
    );
};

PriceInput.propTypes = {
    currency: PropTypes.string
};

export default PriceInput;
