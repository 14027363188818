import { FunctionField } from 'react-admin';

function getAddressString(address) {
    if (address == null) {
        return '-';
    }

    const { city, line1, line2, postalCode } = address;

    let addressLine = line1;
    if (line2 != null) {
        addressLine += `, ${line2}`;
    }

    return `${addressLine}, ${postalCode} ${city}`;
}

const AddressField = () => {
    return (
        <FunctionField render={(record) => getAddressString(record?.address)} />
    );
};

export default AddressField;
