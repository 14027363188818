import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {Title} from 'react-admin';
import axios from 'axios';

const ControlPanels = () => {

    const handleDownload = (urlToCatch) => {
        const token = localStorage.getItem('token');
        const config = {
            headers: { Authorization: `JWT ${token}` },
            responseType: 'blob'
        }
        axios.get(urlToCatch, config)
            .then(response => {
                // Load datas and store it into a blob, represented by an URL
                const blob = new Blob([response.data], {type:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})
                const url = window.URL.createObjectURL(blob);

                // Get fileName in contentDisposition
                const contentDisposition = response.request.getResponseHeader('Content-Disposition');
                const match = contentDisposition.match(/filename=(.+)/);
                const fileName = match && match[1] ? match[1] : 'sensay-backoffice-file.xlsx';

                // Create link and activate it
                const a = document.createElement('a');
                a.href = url;
                a.download = fileName;
                a.click();
                window.URL.revokeObjectURL(url);
            })
            .catch(error => {
                console.error('Erreur de Téléchargement du fichier : ' + error);
            });
    }

    const handleDownloadInvoicing = () => {
        handleDownload(`${process.env.REACT_APP_API_URL}/license-pilot`);
    };

    const handleDownloadTrainerPilot = () => {
        handleDownload(`${process.env.REACT_APP_API_URL}/trainer-pilot`);
    };


    return (
        <>
            <Card sx={{
                mt: 2,
                ':hover': {
                    backgroundColor: '#FAFAFD',
                    boxShadow: 3
                }
            }}>
                <CardContent style={{cursor: 'pointer'}} onClick={handleDownloadTrainerPilot}>
                    <Title title="Tableaux de contrôle"/>

                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <FileDownloadIcon style={{marginRight: '16px', fontSize: 32}} color="primary"/>
                        <div>
                            <Typography variant="h6" component="div">
                                Pilotage des formateurs
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Tableau EXCEL présentant la liste des formateurs accompagnés des éléments utiles au filtrage
                                (infos administratives, de diplômes et documents enregistrés)
                            </Typography>
                        </div>
                    </div>
                </CardContent>
            </Card>

            <Card sx={{
                mt: 2,
                ':hover': {
                    backgroundColor: '#FAFAFD',
                    boxShadow: 3
                }
            }}>
                <CardContent style={{cursor: 'pointer'}} onClick={handleDownloadInvoicing}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <FileDownloadIcon style={{marginRight: '16px', fontSize: 32}} color="primary"/>
                        <div>
                            <Typography variant="h6" component="div">
                                Coordonnées de facturation
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Tableau EXCEL présentant la liste des formateurs actifs, leur e-mail
                                et leurs coordonnées de facturation (adresse, CP, ville)
                            </Typography>
                        </div>
                    </div>
                </CardContent>
            </Card>
        </>
    );
}


export default ControlPanels;