import {
    BooleanField,
    Datagrid,
    DateTimeInput,
    EditButton,
    List,
    ReferenceInput,
    ReferenceOneField,
    SearchInput,
    SelectField,
    SelectInput,
    ShowButton,
    TextField
} from 'react-admin';
import BulletListField from '../components/BulletListField';
import CustomDateField from '../components/CustomDateField';
import PriceField from '../components/PriceField';
import { SessionTypes, TrainingFormats, TrainingLevels, TrainingTypes } from './trainingEnums';

const TrainingList = () => {
    const postFilters = [
        <SearchInput key='Title' source='q' alwaysOn />,
        <ReferenceInput key='trainer' label='Trainer' source='trainer' reference='trainers' alwaysOn>
            <SelectInput optionText='name' />
        </ReferenceInput>,
        <DateTimeInput key='createdAt_gte' source='createdAt_gte' label='Created after' />,
        <DateTimeInput key='createdAt_lte' source='createdAt_lte' label='Created before' />
    ];

    return (
        <List exporter={false} filters={postFilters} filter={{ deletedAt: null }} sort={{ field: 'createdAt', order: 'DESC' }}>
            <Datagrid>
                <TextField source='title' />
                <ReferenceOneField source='trainer' target='id' reference='trainers'>
                    <TextField source='name' />
                </ReferenceOneField>
                <BulletListField source='sessionType' formatter={(value) => SessionTypes.translate(value)} />
                <SelectField source='level' choices={[
                    { id: TrainingLevels.STARTER, name: 'Debutant' },
                    { id: TrainingLevels.INTERMEDIATE, name: 'Intermediaire' },
                    { id: TrainingLevels.EXPERT, name: 'Expert' }
                ]} />
                <SelectField source='type' choices={[
                    { id: TrainingTypes.CERTIFYING, name: 'Certifiante' },
                    { id: TrainingTypes.QUALIFYING, name: 'Qualifiante' },
                    { id: TrainingTypes.VAE, name: 'VAE' }
                ]} />
                <BulletListField source='format' formatter={(value) => TrainingFormats.translate(value)} />
                <PriceField source='price' />
                <CustomDateField source='createdAt' />
                <BooleanField source='isVisible' />
                <ShowButton />
                <EditButton />
            </Datagrid>
        </List>
    );
};

export default TrainingList;
