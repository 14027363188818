import Stack from '@mui/material/Stack';
import { BooleanField, EditButton, FunctionField, SelectField, SimpleShowLayout, TextField, UrlField, useRecordContext } from 'react-admin';
import BulletListField from '../components/BulletListField';
import CustomDateField from '../components/CustomDateField';
import CustomFileField from '../components/CustomFileField';
import QualiopiCategories from './qualiopiCategories';
import TitleDiv from './TitleDiv';
import TrainerCompanyFunctions from './trainerCompanyFunctions';
import { formatLocalisation } from '../utils/utils';

const TrainerAdmin = () => {
    const admin = useRecordContext();

    let children = null;
    let editButton = null;

    if (admin == null) {
        children = <div>-</div>;
    } else {
        children = (
            <SimpleShowLayout>
                <TextField source='companyName' emptyText='-' />
                <TextField source='legalStructure' emptyText='-' />
                <TextField source='siretNumber' emptyText='-' />
                <SelectField source='function' emptyText='-' choices={
                    Object.values(TrainerCompanyFunctions.values).map((x) => ({ id: x, name: TrainerCompanyFunctions.translate(x) }))
                } />

                <TextField source='address' emptyText='-' />
                <FunctionField source='localisation' render={(x) => formatLocalisation(x.localisation)} />
                <UrlField source='website' emptyText='-' />
                <TextField source='activityDeclarationNumber' emptyText='-' />

                <BooleanField source='hasBpf' />
                <CustomDateField source='bpfDate' options={{ year: 'numeric', month: 'numeric', day: 'numeric' }} emptyText='-' />
                <TextField source='bpf' emptyText='-' />
                <BooleanField source='hasQualiopi' />
                <CustomDateField source='qualiopiValidityDate' options={{ year: 'numeric', month: 'numeric', day: 'numeric' }} emptyText='-' />
                <BulletListField source='qualiopiActionCategory' formatter={(value) => QualiopiCategories.translate(value)} />

                <BulletListField source='teacherDocuments.diplomas'
                    formatter={(item) => <CustomFileField record={item} />} />
                <BulletListField source='teacherDocuments.certifications'
                    formatter={(item) => <CustomFileField record={item} />} />
                <CustomFileField source='teacherDocuments.resume' />
                <UrlField source='teacherDocuments.linkedIn' emptyText='-' />

                <CustomFileField source='companyDocuments.kbis' />
                <CustomFileField source='companyDocuments.assuranceRcPro' />
                <CustomFileField source='companyDocuments.urssaf' />
                <CustomFileField source='companyDocuments.attestationSignedByEmployer' />

                <CustomFileField source='certificationDocuments.declarationActivityOfTrainingPartner' />
                <CustomFileField source='certificationDocuments.qualiopi' />
                <CustomFileField source='certificationDocuments.attestationSignedByPartner' />

                <BulletListField source='otherFiles' formatter={(item) => <CustomFileField record={item} />} />

                <CustomDateField source='createdAt' emptyText='-' />
                <CustomDateField source='updatedAt' emptyText='-' />
            </SimpleShowLayout>
        );

        editButton = <EditButton resource='trainer-admins' />
    }

    return (
        <Stack>
            <Stack direction='row' justifyContent='space-between'>
                <TitleDiv>Données administratives</TitleDiv>
                {editButton}
            </Stack>
            {children}
        </Stack>
    );
};

export default TrainerAdmin;
