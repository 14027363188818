import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useEffect, useState } from 'react';
import { EditButton, SimpleShowLayout, useListContext, useResourceContext } from 'react-admin';
import { getCustomTableShow } from '../components/CustomTableShow';
import Feedback from '../trainings/TrainingFeedback';
import dataHandler from '../trainings/TrainingShowDataHandler';
import TitleDiv from './TitleDiv';

const TrainerTrainings = (props) => {
    const { data, isLoading } = useListContext();

    const resource = useResourceContext(props);
    const [fields, setFields] = useState();

    useEffect(() => {
        const extraFields = [
            <Feedback key={'feedback_0'} />
        ]

        getCustomTableShow(resource, props, dataHandler, { extraFields, onlyFields: true }).then((x) => setFields(x));
    }, [ props, resource ]);

    if (isLoading) {
        return (
            <div>
                <TitleDiv>Trainings</TitleDiv>
                <p>Loading...</p>
            </div>
        );
    }

    if (data == null || data.length === 0) {
        return <div></div>;
    }

    return (
        <div>
            <TitleDiv>Trainings</TitleDiv>
            {data.map(record => (
                <Accordion key={record.id}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} id={`training_panel_${record.id}`}>
                        <Stack direction='row' justifyContent='space-between' width='100%'>
                            <Typography>{record.title}</Typography>
                            <EditButton record={record} resource='trainings' />
                        </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                        <SimpleShowLayout record={record}>
                            {fields}
                        </SimpleShowLayout>
                        <Typography>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            ))}
        </div>
    );
};

export default TrainerTrainings;
