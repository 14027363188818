import { downloadCSV } from 'react-admin';
import jsonExport from 'jsonexport/dist';
import { StudentBudget, StudentExperience, StudentStatus, StudentTrainingType } from './studentEnums';
import { TrainingFormats } from '../trainings/trainingEnums';

const exporter = (records, fetchRelatedRecords) => {
    const fetchResults = Promise.all([
        fetchRelatedRecords(records, 'domains', 'domains'),
        fetchRelatedRecords(records, 'interests', 'student-preferences')
    ]);

    fetchResults.then(([domains, interests]) => {
        const results = [];

        records.forEach(record => {
            const studentDomains = record.domains.map((studentDomain) => domains[studentDomain]?.name);
            const studentInterests = record.interests.map((studentInterest) => interests[studentInterest]?.name);

            const newRecord = {
                ...record,
                domains: studentDomains,
                interests: studentInterests,
                status: StudentStatus.translate(record.status),
                trainingType: StudentTrainingType.translate(record.trainingType),
                experience: StudentExperience.translate(record.experience),
                preferredFormat: record.preferredFormat.map((format) => TrainingFormats.translate(format)),
                yearlyBudget: StudentBudget.translate(record.yearlyBudget)
            };

            delete newRecord.deletedAt;
            delete newRecord.isStudent;

            results.push(newRecord);
        });

        return jsonExport(results, {}, (err, csv) => {
            downloadCSV(csv, 'students');
        });
    });
};

export default exporter;
