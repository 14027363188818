import PropTypes from 'prop-types';
import Chip from '@mui/material/Chip';
import { FunctionField } from 'react-admin';
import { TrainerTags } from '../trainers/trainerTags';

const TrainerTagField = ({ size }) => (
    <FunctionField source='tag' render={({ tag }) => {
        const { color, backgroundColor } = TrainerTags.getColors(tag);
        return <Chip sx={{ color, backgroundColor }} size={size} label={tag} />
    }} />
);

TrainerTagField.propTypes = {
    size: PropTypes.oneOf(['small', 'medium'])
}

export default TrainerTagField;
