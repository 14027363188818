import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import get from 'lodash.get';
import { BooleanField, EditButton, ReferenceArrayField, SimpleShowLayout, TextField, useRecordContext } from 'react-admin';
import { styled } from '@mui/material/styles';
import BulletListField from '../components/BulletListField';
import CustomDateField from '../components/CustomDateField';
import BulletList from '../components/BulletList';
import TitleDiv from './TitleDiv';
import VideoField from '../components/VideoField';
import { formatLocalisation } from '../utils/utils';

const ExpertiseDiv = styled('div')(({ theme }) => ({
    fontSize: theme.typography.body2.fontSize
}));

const ExpertiseList = ({ source }) => {
    const record = useRecordContext();

    if (record == null) {
        return <span>-</span>;
    }

    const items = get(record, source);

    if (items == null || !Array.isArray(items) || items.length === 0) {
        return <span>-</span>;
    }

    return items.map((x) => (
        <ExpertiseDiv key={x._id} style={{ marginBlockEnd: 5 }}>
            <div style={{ fontWeight: 'bold' }}>{x.title}</div>
            <div style={{ paddingInlineStart: 10 }}>{x.description}</div>
        </ExpertiseDiv>
    ));
};

ExpertiseList.propTypes = {
    source: PropTypes.string
};

const TrainerProfile = () => {
    const profile = useRecordContext();

    let children = null;
    let editButton = null;

    if (profile == null) {
        children = <div>-</div>;
    } else {
        children = (
            <SimpleShowLayout>
                <TextField source='presentation' emptyText='-' />
                <BulletListField source='localisation' formatter={(loc) => formatLocalisation(loc)} />
                <BooleanField source='referentHandicap' />
            
                <ReferenceArrayField source='domains' reference='domains'>
                    <BulletList source='name' />
                </ReferenceArrayField>

                <BulletListField source='experience.savoirFaire' />
                <BulletListField source='experience.values' />
                <BulletListField source='experience.savoirEtre' />

                <BulletListField source='education.degrees' />
                <BulletListField source='education.certifications' />

                <ExpertiseList source='expertise' />

                <VideoField source='video' />

                <CustomDateField source='createdAt' emptyText='-' />
                <CustomDateField source='updatedAt' emptyText='-' />
            </SimpleShowLayout>
        );

        editButton = <EditButton resource='trainer-profiles' />
    }

    return (
        <Stack>
            <Stack direction='row' justifyContent='space-between'>
                <TitleDiv>Profil</TitleDiv>
                {editButton}
            </Stack>
            {children}
        </Stack>
    );
};

export default TrainerProfile;
