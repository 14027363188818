import CircleIcon from '@mui/icons-material/Circle';
import Typography from '@mui/material/Typography'
import { BooleanField, CheckboxGroupInput, Datagrid, DateTimeInput, EditButton, List, SearchInput, SelectArrayInput, ShowButton, TextField } from 'react-admin';
import CustomDateField from '../components/CustomDateField';
import TrainerStatusField from '../components/TrainerStatusField';
import exporter from './trainerExporter';
import TrainerLicenseField from '../components/TrainerLicenseField';
import TrainerTagField from '../components/TrainerTag';
import { TrainerTags } from './trainerTags';

const TrainerList = () => {
    const postFilters = [
        <SearchInput key='search' source='q' alwaysOn />,
        <CheckboxGroupInput key='status' source='status' label='Status' alwaysOn choices={[
            { id: 'created', name: 'Créé' },
            { id: 'submitted', name: 'A valider' },
            { id: 'validated', name: 'Validé' },
        ]} />,
        <SelectArrayInput key='tag' source='tag' 
            choices={
                TrainerTags.tags.map(({ name, backgroundColor }) => ({ id: name, name, backgroundColor }))
            }
            optionText={({ name, backgroundColor }) => (
                <Typography my={0.5}>
                    <CircleIcon sx={{ color: backgroundColor, pr: 1, verticalAlign: 'middle' }} />{name}
                </Typography>
            )}
        />,
        <DateTimeInput key='createdAt_gte' source='createdAt_gte' label='Created after' />,
        <DateTimeInput key='createdAt_lte' source='createdAt_lte' label='Created before' />,
        <DateTimeInput key='lastLoginAt_gte' source='lastLoginAt_gte' label='Last login after' />,
        <DateTimeInput key='lastLoginAt_lte' source='lastLoginAt_lte' label='Last login before' />
    ];

    return (
        <List exporter={exporter} filters={postFilters} filter={{ deletedAt: null }} sort={{ field: 'createdAt', order: 'DESC' }}>
            <Datagrid>
                <TextField source='firstname' />
                <TextField source='lastname' />
                <TextField source='email' />
                <TextField source='phone' />
                <TrainerStatusField />
                <TrainerLicenseField />
                <CustomDateField source='licenseEndDate' emptyText='-' />
                <TrainerTagField source='tag' />
                <CustomDateField source='lastLoginAt' emptyText='-' />
                <CustomDateField source='createdAt' />
                <BooleanField source='isDisabled' />
                <ShowButton />
                <EditButton />
            </Datagrid>
        </List>
    );
};

export default TrainerList;
