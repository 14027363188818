import {DateInput, Edit, ReferenceInput, SimpleForm, TextInput} from 'react-admin';

const RfiEdit = () => (
    <Edit>
        <SimpleForm>
            <TextInput source="status" />
            <ReferenceInput source="toTrainer" reference="trainers" />
            <ReferenceInput source="toTraining" reference="trainings" label="Training (if selected by visitor)" />
            <TextInput source="fromFirstname" />
            <TextInput source="fromLastname" />
            <TextInput source="fromEmail" />
            <TextInput source="fromPhone" />
            <TextInput source="fromStatus" />
            <TextInput source="request" fullWidth multiline />
            <DateInput source="createdAt" />
            <DateInput source="updatedAt" />
            <TextInput source="id" />
        </SimpleForm>
    </Edit>
);

export default RfiEdit;