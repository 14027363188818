import { BooleanField, Datagrid, DateTimeInput, EditButton, List, ReferenceInput, ReferenceOneField, SelectField, SelectInput, TextField } from 'react-admin';
import CustomDateField from '../components/CustomDateField';
import exporter from './connectionExporter';
import { FollowUpStatus } from './connectionEnums';

const ConnectionList = () => {
    const postFilters = [
        <ReferenceInput key='trainer' label='Trainer' source='trainer' reference='trainers' alwaysOn>
            <SelectInput optionText='name' />
        </ReferenceInput>,
        <DateTimeInput key='createdAt_gte' source='createdAt_gte' label='Created after' />,
        <DateTimeInput key='createdAt_lte' source='createdAt_lte' label='Created before' />,
    ];

    return (
        <List exporter={exporter} filters={postFilters} sort={{ field: 'createdAt', order: 'DESC' }}>
            <Datagrid bulkActionButtons={false} isRowSelectable={() => false}>
                <ReferenceOneField source='trainer' target='id' reference='trainers'>
                    <TextField source='name' />
                </ReferenceOneField>
                <ReferenceOneField source='student' target='id' reference='students'>
                    <TextField source='name' />
                </ReferenceOneField>
                <CustomDateField source='visioDate' />
                <BooleanField source='isTrainerConnected' label='Formateur connecté' />
                <BooleanField source='isStudentConnected' label='Apprenant connecté' />
                <BooleanField source='participantsJoinedTogether' label='Connectés au même moment' />
                <SelectField source='followUpStatus' choices={[
                    { id: FollowUpStatus.NO_FOLLOWUP, name: 'Sans suite' },
                    { id: FollowUpStatus.VALIDATED, name: 'Validée' }
                ]} />
                <BooleanField source='isCancelled' label='Annulé' />
                <TextField source='cancelledReason' label="Raison de l'annulation" />
                <CustomDateField source='createdAt' />
                <BooleanField source='isBillable' />
                <TextField source='notBillableReason' />
                <EditButton />
            </Datagrid>
        </List>
    );
};

export default ConnectionList;
