import { useEffect, useState } from 'react';
import { ReferenceField, SaveButton, TextField, TextInput, Toolbar, useResourceContext } from 'react-admin';
import CustomFileField from '../components/CustomFileField';
import { CustomFileInput } from '../components/CustomFileInput';
import { getCustomTableEdit, getFieldValidation } from '../components/CustomTableEdit';
import LocalisationInput from '../components/LocalisationInput';

const unsupportedFields = ['createdAt', 'updatedAt', 'isSubmitted'];
const freeTextFields = ['presentation', 'description'];

const customHandler = (fields, fieldName, fieldList, index) => {
    if (unsupportedFields.includes(fieldName)) {
        return true;
    }

    if (freeTextFields.includes(fieldName)) {
        const validate = getFieldValidation(fields, fieldName);

        fieldList.push(
            <TextInput key={index} multiline fullWidth source={fieldName} validate={validate} />
        );

        return true;
    }

    if (fieldName === 'localisation') {
        const validate = getFieldValidation(fields, fieldName);

        fieldList.push(
            <LocalisationInput source={fieldName} key={index} label='Localisation' validate={validate} multiple />
        );

        return true;
    }

    if (fieldName === 'trainer') {
        fieldList.push(
            <ReferenceField key={index} reference='trainers' source={fieldName} sx={{ mb: 2, fontSize: '120%'  }} link='show'>
                <div>Profile of <TextField sx={{ fontSize: '100%' }} source='name' /></div>
            </ReferenceField>
        );

        return true;
    }

    if (fieldName === 'video') {
        fieldList.push(
            <CustomFileInput accept='video/mp4' filetype='trainer-profile/video'
                maxSize={20e6} key={index} source={fieldName} style={{ marginBottom: '1em' }}>
                <CustomFileField />
            </CustomFileInput>
        );

        return true;
    }

    return false;
};

const TrainerProfileEdit = (props) => {
    const resource = useResourceContext(props);

    const [data, setData] = useState();

    useEffect(() => {
        const propsToUse = {
            redirect: (resource, id, data) => `trainers/${data.trainer}/show`
        };

        const formProps = {
            toolbar: (
                <Toolbar>
                    <SaveButton />
                </Toolbar>
            )
        };

        getCustomTableEdit(resource, propsToUse, customHandler, { formProps }).then((data) => setData(data));
    }, [ props, resource ]);

    if (data == null) {
        return <div></div>;
    }

    return data;
};

export default TrainerProfileEdit;
