import {DateInput, Edit, NumberInput, ReferenceInput, SimpleForm, TextInput} from 'react-admin';

const TransactionEdit = () => (
    <Edit>
        <SimpleForm>
            <ReferenceInput source="trainer" reference="trainers" />
            <DateInput source="createdAt" />
            <NumberInput source="credits" />
            <TextInput source="status" />
            <TextInput source="type" />
            <TextInput source="rfi" />
            <TextInput source="visio" />
            <TextInput source="id" />
        </SimpleForm>
    </Edit>
);

export default TransactionEdit;