import Chip from '@mui/material/Chip';
import { useRecordContext } from 'react-admin';
import { LicenseRequestStatus } from '../license-request/licenseRequestEnums';

const LicenseRequestStatusField = (props) => {
    const licenseRequest = useRecordContext();

    switch (licenseRequest?.status) {
    case LicenseRequestStatus.values.NEW_MODEL:
        return <Chip label={LicenseRequestStatus.translate(LicenseRequestStatus.values.NEW_MODEL)} color='info' {...props} />
    case LicenseRequestStatus.values.NOT_TREATED:
        return <Chip label={LicenseRequestStatus.translate(LicenseRequestStatus.values.NOT_TREATED)} color='default' {...props} />
    case LicenseRequestStatus.values.INVOICE_SENT:
        return <Chip label={LicenseRequestStatus.translate(LicenseRequestStatus.values.INVOICE_SENT)} color='info' {...props} />
    case LicenseRequestStatus.values.PAYED:
        return <Chip label={LicenseRequestStatus.translate(LicenseRequestStatus.values.PAYED)} color='success' {...props} />
    default:
        return <Chip label='Inconnu' {...props} />
    }
};

export default LicenseRequestStatusField;