import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { Title } from 'react-admin';
import DashboardMetrics from './DashboardMetrics';
import DashboardTrainersToValidate from './DashboardTrainersToValidate';
import DashboardLicenseRequests from './DashboardLicenseRequests';

const Dashboard = () => {
    return (
        <Card sx={{ mt: 2 }}>
            <Title title='Dashboard' />
            <CardContent>
                <Stack gap={3} divider={<Divider />}>
                    <DashboardMetrics />
                    <DashboardLicenseRequests />
                    <DashboardTrainersToValidate />
                </Stack>
            </CardContent>
        </Card>
    );
}

export default Dashboard;
