import { BooleanField, Datagrid, Labeled, Pagination, ReferenceManyField, TextField } from 'react-admin';
import CustomDateField from '../components/CustomDateField';
import RatingField from '../components/RatingField';

const Feedback = () => (
    <Labeled>
        <ReferenceManyField label='Feedback' reference='feedback' target='training' emptyText='-' pagination={<Pagination />}>
            <Datagrid bulkActionButtons={false}>
                <CustomDateField source='createdAt' />
                <RatingField source='programConformity' size='small' />
                <RatingField source='visioQuality' size='small' />
                <RatingField source='trainerExchange' size='small' />
                <RatingField source='service' size='small' />
                <BooleanField source='isInterestedInTraining' />
                <TextField source='comments' emptyText='-' />
            </Datagrid>
        </ReferenceManyField>
    </Labeled>
);

export default Feedback;
