import PropTypes from 'prop-types';
import { NumberField } from 'react-admin';

const PriceField = (props) => {
    const {
        currency = 'EUR',
        ...rest
    } = props;

    return (
        <NumberField {...rest} options={{ style: 'currency', currency }} locales='fr-FR'></NumberField>
    );
};

PriceField.propTypes = {
    currency: PropTypes.string
};

export default PriceField;
