import {
    BooleanField, Datagrid,
    DateField,
    EmailField, FunctionField, ReferenceArrayField,
    Show, SimpleShowLayout, TextField
} from 'react-admin';
import BulletListField from '../components/BulletListField';
import BulletList from '../components/BulletList';
import {displayFieldForPlural} from '../utils/utils';

const FormOrientationShow = () => (
    <Show>
        <SimpleShowLayout>
            <TextField source="formType" label="Type de formulaire"/>
            <TextField source="situation"/>
            <TextField source="statutPro" label="Statut professionnel actuel"/>

            <h3>Trouver mon formateur</h3>
            <TextField source="impact" label="Impact recherché"/>
            <ReferenceArrayField source="domains" reference="domains" emptyText='-' label="Domaines recherchés">
                <BulletList source={displayFieldForPlural('domains')}/>
            </ReferenceArrayField>

            <h3>Bilan de compétences</h3>
            <BulletListField source="needs" label="Besoins"></BulletListField>
            <TextField source="satisfaction" label="Niveau de satisfaction actuel (1 à 5)"/>
            <TextField source="workSince" label="En poste depuis..."/>
            <BulletListField source="sectors" label="Secteurs d'activité"></BulletListField>
            <TextField source="studyLevel" label="Niveau d'études"/>

            <h3>Format et financement</h3>
            <BulletListField source="personalite" label="Personnalité recherchée"></BulletListField>
            <TextField source="format"/>
            <TextField source="duree"/>
            <TextField source="budget"/>
            <TextField source="modeFinancing"/>


            <hr/>
            <h3>Coordonnées</h3>
            <TextField source="firstname"/>
            <TextField source="lastname"/>
            <EmailField source="email"/>
            <TextField source="phone" emptyText="-"/>
            <BooleanField source="moreInfos" label="Souhaite être recontacté pour des questions de financement, etc."/>

            <hr/>
            <h3>Informations techniques</h3>
            <DateField source="createdAt" label="Date de création"/>
            <DateField source="updatedAt"/>
            <TextField source="visitorOrigin" label="Origine de la visite"/>

            <hr/>
            <h3>Suggestion de formateurs et demandes de contacts</h3>
            <FunctionField
                source="suggestedTrainers"
                render={record => `${record.suggestedTrainers.length} suggestions`}
            />
            <br/>
            <ReferenceArrayField source="contactedTrainers" reference="trainers" label="Formateurs contactés (demande d'info)">
                <Datagrid rowClick="show" isRowSelectable={(record) => false}>
                    <TextField source="lastname"/>
                    <TextField source="firstname"/>
                    <EmailField source="email"/>
                    <TextField source="phone"/>
                </Datagrid>
            </ReferenceArrayField>
        </SimpleShowLayout>
    </Show>
);

export default FormOrientationShow;