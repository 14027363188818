export const SessionTypes = Object.freeze({
    values: {
        INDIVIDUAL: 'individual',
        COLLECTIVE: 'collective',
    },
    translate(value) {
        if (value == null) {
            return value;
        }

        switch (value) {
        case this.values.INDIVIDUAL:
            return 'Individuelle';
        case this.values.COLLECTIVE:
            return 'Collective';
        default:
            throw new RangeError(`Invalid value '${value}'`);
        }
    }
});

export const TrainingEligibles = Object.freeze({
    CPF: 'cpf',
    OPCO: 'opco',
    FOND_PROPRE: 'fond_propre',
    POLE_EMPLOI: 'pole_emploi'
});

export const TrainingFormats = Object.freeze({
    values: Object.freeze({
        REMOTE: 'remote',
        IN_PERSON: 'in_person',
    }),
    translate(value) {
        if (value == null) {
            return value;
        }

        switch (value) {
        case this.values.REMOTE:
            return 'A distance';
        case this.values.IN_PERSON:
            return 'En présentiel';
        default:
            throw new RangeError(`Invalid value '${value}'`);
        }
    }
});

export const TrainingLevels = Object.freeze({
    STARTER: 'starter',
    INTERMEDIATE: 'intermediate',
    EXPERT: 'expert'
});

export const TrainingTypes = Object.freeze({
    QUALIFYING: 'qualifying',
    CERTIFYING: 'certifying',
    VAE: 'vae'
});
