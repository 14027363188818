import {Datagrid, DateField, EmailField, List, ReferenceField, TextField} from 'react-admin';

const FormContactList = () => (
    <List sort={{ field: 'createdAt', order: 'DESC' }}>
        <Datagrid rowClick='show'>
            <DateField source='createdAt' label="Date" />
            <TextField source='firstname' label='Prénom' />
            <TextField source='lastname' label='Nom' />
            <EmailField source='email' />
            <TextField source='message' />
            <ReferenceField source="student" reference="students"  label="Apprenant" emptyText='-'/>
        </Datagrid>
    </List>
);

export default FormContactList;