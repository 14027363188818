import { downloadCSV } from 'react-admin';
import jsonExport from 'jsonexport/dist';
import { FollowUpStatus } from './connectionEnums';

const exporter = (records, fetchRelatedRecords) => {
    const fetchResults = Promise.all([
        fetchRelatedRecords(records, 'trainer', 'trainers'),
        fetchRelatedRecords(records, 'student', 'students')
    ])

    fetchResults.then(([trainers, students]) => {
        const results = [];

        records.forEach(record => {
            const newRecord = {
                ...record,
                trainer: trainers[record.trainer].name,
                student: students[record.student].name,
            };

            if (record.followUpStatus === FollowUpStatus.NO_FOLLOWUP) {
                newRecord.followUpStatus = 'Sans suite';
            } else if (record.followUpStatus === FollowUpStatus.VALIDATED) {
                newRecord.followUpStatus = 'Validée';
            }

            delete newRecord.visio;

            results.push(newRecord);
        });

        return jsonExport(results, {}, (err, csv) => {
            downloadCSV(csv, 'connections');
        });
    });
};

export default exporter;
