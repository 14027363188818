import { useEffect, useState } from 'react';
import { CheckboxGroupInput, SelectArrayInput, SelectInput, TextInput, useResourceContext } from 'react-admin';
import CustomFileField from '../components/CustomFileField';
import { CustomFileInput } from '../components/CustomFileInput';
import { getCustomTableEdit, getFieldValidation } from '../components/CustomTableEdit';
import LocalisationInput from '../components/LocalisationInput';
import PriceInput from '../components/PriceInput';
import { SessionTypes, TrainingEligibles, TrainingFormats, TrainingLevels, TrainingTypes } from './trainingEnums';

const unsupportedFields = ['createdAt', 'updatedAt', 'deletedAt', 'isVisible', 'feedbackScore', 'viewCount', 'trainerName'];

const dataHandler = (fields, fieldName, fieldList, index) => {
    if (unsupportedFields.includes(fieldName)) {
        return true;
    }

    if (fieldName === 'localisation') {
        const validate = getFieldValidation(fields, fieldName);

        fieldList.push(
            <LocalisationInput source={fieldName} key={index} label='Localisation' validate={validate} />
        );

        return true;
    }

    if (fieldName === 'summary') {
        const validate = getFieldValidation(fields, fieldName);

        fieldList.push(
            <TextInput source={fieldName} key={index} validate={validate} multiline fullWidth />
        );

        return true;
    }

    if (fieldName === 'sessionType') {
        const validate = getFieldValidation(fields, fieldName);

        fieldList.push(
            <CheckboxGroupInput key={index} source='sessionType' validate={validate} choices={[
                { id: SessionTypes.values.COLLECTIVE, name: 'Collective' },
                { id: SessionTypes.values.INDIVIDUAL, name: 'Individuelle' }
            ]} />
        );

        return true;
    }

    if (fieldName === 'type') {
        const validate = getFieldValidation(fields, fieldName);

        fieldList.push(
            <SelectInput key={index} source='type' validate={validate} choices={[
                { id: TrainingTypes.CERTIFYING, name: 'Certifiante' },
                { id: TrainingTypes.QUALIFYING, name: 'Qualifiante' },
                { id: TrainingTypes.VAE, name: 'VAE' }
            ]} />
        );

        return true;
    }

    if (fieldName === 'level') {
        const validate = getFieldValidation(fields, fieldName);

        fieldList.push(
            <SelectInput key={index} source='level' validate={validate} choices={[
                { id: TrainingLevels.STARTER, name: 'Debutant' },
                { id: TrainingLevels.INTERMEDIATE, name: 'Intermediaire' },
                { id: TrainingLevels.EXPERT, name: 'Expert' }
            ]} />
        );

        return true;
    }

    if (fieldName === 'format') {
        const validate = getFieldValidation(fields, fieldName);

        fieldList.push(
            <CheckboxGroupInput key={index} source='format' validate={validate} choices={[
                { id: TrainingFormats.values.IN_PERSON, name: 'Presentiel' },
                { id: TrainingFormats.values.REMOTE, name: 'Distanciel' }
            ]} />
        );

        return true;
    }

    if (fieldName === 'eligibles') {
        const validate = getFieldValidation(fields, fieldName);

        fieldList.push(
            <SelectArrayInput key={index} source={fieldName} validate={validate} choices={[
                { id: TrainingEligibles.CPF, name: 'CPF' },
                { id: TrainingEligibles.FOND_PROPRE, name: 'Fond propre' },
                { id: TrainingEligibles.OPCO, name: 'OPCO' },
                { id: TrainingEligibles.POLE_EMPLOI, name: 'Pole emploi' }
            ]} />
        );

        return true;
    }

    if (fieldName === 'price') {
        const validate = getFieldValidation(fields, fieldName);

        fieldList.push(
            <PriceInput key={index} source={fieldName} validate={validate} />
        );

        return true;
    }

    if (fieldName === 'video') {
        fieldList.push(
            <CustomFileInput accept='video/mp4' filetype='training/video'
                maxSize={20e6} key={index} source={fieldName} style={{ marginBottom: '1em' }}>
                <CustomFileField />
            </CustomFileInput>
        );

        return true;
    }

    return false;
};

const TrainingEdit = (props) => {
    const resource = useResourceContext(props);
    const [data, setData] = useState();

    useEffect(() => {
        getCustomTableEdit(resource, props, dataHandler).then((data) => setData(data));
    }, [ props, resource ]);

    if (data == null) {
        return <div></div>;
    }

    return data;
};

export default TrainingEdit;
