import {Edit, ReferenceInput, SimpleForm, TextInput, TopToolbar, ListButton, useRecordContext} from 'react-admin';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const SubdomainEditActions = ({ basePath, data, resource }) => (
    <TopToolbar>
        <ListButton basePath={basePath} label="Cancel" icon={<ChevronLeftIcon />} />
    </TopToolbar>
);

const SubdomainTitle = () => {
    const record = useRecordContext();
    return <span>Subdomain: {record ? `"${record.name}"` : ''}</span>;
};

const SubdomainEdit = () => (
    <Edit redirect="list" actions={<SubdomainEditActions />} title={<SubdomainTitle/>}>
        <SimpleForm>
            <TextInput source="name" label="Subdomain name" />
            <ReferenceInput source="domain" label="Domain" reference="domains" sort={{field:'name', order:'ASC'}} perPage={1000} />
        </SimpleForm>
    </Edit>
);


export default SubdomainEdit;