export const getPlural = (singularWord) => {
    const lastChar = singularWord.substr(singularWord.length - 1);

    if (lastChar === 'y') {
        return `${singularWord.substring(0, singularWord.length - 1)}ies`;
    }
    return `${singularWord}s`;
};

export const displayFieldForPlural = () => {
    return 'name';
};

export const formatDate = (date) => {
    const dateValue = date instanceof Date
        ? date
        : new Date(date);

    const formatter = new Intl.DateTimeFormat('fr', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
    });

    return formatter.format(dateValue);
};

function getTypeDescription(localisation) {
    switch (localisation?.type) {
    case 'country':
        return '';
    case 'departement':
        return ' (département)';
    case 'region':
        return ' (région)';
    default:
        return localisation?.departement == null
            ? ''
            : ` - ${localisation.departement}`;
    }
}

export const formatLocalisation = (localisation) => {
    if (localisation == null) {
        return '';
    }

    const typeDescription = getTypeDescription(localisation);

    return `${localisation.name}${typeDescription}`;
};
