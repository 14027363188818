const QualiopiCategories = Object.freeze({
    values: Object.freeze({
        FORMATION: 'formation',
        COMPETENCES: 'compentences',
        VAE: 'vae',
        APPRENTISSAGE: 'apprentissage',
    }),
    translate(value) {
        if (value == null) {
            return value;
        }

        switch (value) {
        case this.values.APPRENTISSAGE:
            return 'Apprentissage';
        case this.values.COMPETENCES:
            return 'Bilan de compétences';
        case this.values.FORMATION:
            return 'Action de formation';
        case this.values.VAE:
            return 'VAE';
        default:
            return value;
        }
    }
});

export default QualiopiCategories;
