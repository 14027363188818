import {
    ArrayField,
    Datagrid,
    FunctionField,
    SelectField,
    TextField
} from 'react-admin';
import BulletListField from '../components/BulletListField';
import PriceField from '../components/PriceField';
import VideoField from '../components/VideoField';
import { SessionTypes, TrainingEligibles, TrainingFormats, TrainingLevels, TrainingTypes } from './trainingEnums';
import { formatLocalisation } from '../utils/utils';

export default function dataHandler(fields, fieldName, fieldList, index) {
    if (fieldName === 'deletedAt') {
        return true;
    }
    
    if (fieldName === 'programme') {
        fieldList.push(
            <ArrayField key={index} source='programme'>
                <Datagrid bulkActionButtons={false}>
                    <TextField source='title' />
                    <BulletListField source='themes' />
                </Datagrid>
            </ArrayField>
        );
    
        return true;
    }
    
    if (fieldName === 'sessionType') {
        fieldList.push(
            <BulletListField key={index} source='sessionType' formatter={(value) => SessionTypes.translate(value)} />
        );
    
        return true;
    }
    
    if (fieldName === 'type') {
        fieldList.push(
            <SelectField key={index} source='type' choices={[
                { id: TrainingTypes.CERTIFYING, name: 'Certifiante' },
                { id: TrainingTypes.QUALIFYING, name: 'Qualifiante' },
                { id: TrainingTypes.VAE, name: 'VAE' }
            ]} />
        );
    
        return true;
    }
    
    if (fieldName === 'level') {
        fieldList.push(
            <SelectField key={index} source='level' choices={[
                { id: TrainingLevels.STARTER, name: 'Debutant' },
                { id: TrainingLevels.INTERMEDIATE, name: 'Intermediaire' },
                { id: TrainingLevels.EXPERT, name: 'Expert' }
            ]} />
        );
    
        return true;
    }
    
    if (fieldName === 'format') {
        fieldList.push(
            <BulletListField key={index} source='format' formatter={(value) => TrainingFormats.translate(value)} />
        );
    
        return true;
    }
    
    if (fieldName === 'eligibles') {
        fieldList.push(
            <BulletListField key={index} source={fieldName} formatter={(item) => {
                if (item === TrainingEligibles.CPF) return 'CPF';
                if (item === TrainingEligibles.FOND_PROPRE) return 'Fond propre';
                if (item === TrainingEligibles.OPCO) return 'OPCO';
                if (item === TrainingEligibles.POLE_EMPLOI) return 'Pole emploi';
            }} />
        );
    
        return true;
    }
    
    if (fieldName === 'price') {
        fieldList.push(
            <PriceField key={index} source={fieldName} />
        );
    
        return true;
    }
    
    if (fieldName === 'localisation') {
        fieldList.push(
            <FunctionField key={index} source='localisation' render={({ localisation }) => formatLocalisation(localisation)} />
        );
    
        return true;
    }

    if (fieldName === 'video') {
        fieldList.push(
            <VideoField key={index} source='video' />
        );

        return true;
    }
    
    return false;
};
