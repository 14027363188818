import { BooleanField, Datagrid, EditButton, List, ShowButton, TextField } from 'react-admin';
import CustomDateField from '../components/CustomDateField';

const AnnouncementList = () => {
    return (
        <List exporter={false} sort={{ field: 'createdAt', order: 'DESC' }}>
            <Datagrid>
                <TextField source='title' />
                <BooleanField source='isForStudents' />
                <BooleanField source='isForTrainers' />
                <CustomDateField source='startDate' options={{ year: 'numeric', month: 'numeric', day: 'numeric'}} />
                <CustomDateField source='endDate' options={{ year: 'numeric', month: 'numeric', day: 'numeric'}} />
                <CustomDateField source='createdAt' />
                <ShowButton />
                <EditButton />
            </Datagrid>
        </List>
    );
};

export default AnnouncementList;
