import { CheckboxGroupInput, Datagrid, EditButton, EmailField, List, ReferenceField, TextField } from 'react-admin';
import AddressField from '../components/AddressField';
import CustomDateField from '../components/CustomDateField';
import LicenseRequestStatusField from '../components/LicenseRequestStatusField';
import { LicenseRequestStatus } from './licenseRequestEnums';

const LicenseRequestList = () => {
    const filters = [
        <CheckboxGroupInput alwaysOn key='status' source='status' label='Status' choices={
            Object.values(LicenseRequestStatus.values).map((value) => ({ id: value, name: LicenseRequestStatus.translate(value) }))
        } />,
    ];

    return (
        <List exporter={false} filters={filters} sort={{ field: 'createdAt', order: 'DESC' }}>
            <Datagrid>
                <TextField source='name' />
                <EmailField source='email' />
                <AddressField />
                <TextField source='coupon' emptyText='-' />
                <ReferenceField source='sponsoredBy' reference='trainers' emptyText='-' link='show'>
                    <>
                        <TextField source='name' />
                        &nbsp;
                        (<TextField source='sponsorshipCode' />)
                    </>
                </ReferenceField>
                <CustomDateField source='createdAt' />
                <LicenseRequestStatusField />
                <EditButton />
            </Datagrid>
        </List>
    );
};

export default LicenseRequestList;
