import get from 'lodash.get';
import {
    Edit,
    SimpleForm,
    TextInput,
    NumberInput,
    SelectInput,
    AutocompleteArrayInput,
    DateInput,
    BooleanInput,
    ReferenceArrayInput,
    ReferenceInput,
    AutocompleteInput,
    ArrayInput,
    SimpleFormIterator,
    required,
    maxLength,
    Create,
    regex
} from 'react-admin';
import { getFieldsForRessource } from '../fieldsForResource';
import { getPlural, displayFieldForPlural } from '../utils/utils';

const defaultCustomHandled = () => false;

const transformInChoices = (base) => {
    const returnObject = []

    for (const obj in base) {
        returnObject.push({
            id: base[obj],
            name: base[obj]
        });
    }

    return returnObject;
};

const generateFromFields = (fields, customHandledFunc, parentFieldName) => {
    const fieldList = []
    const customHandled = customHandledFunc ?? defaultCustomHandled;

    let index = 0;

    for (const fieldName in fields) {
        let fieldLabel = fieldName.replace(/([A-Z])/g, ' $1').trim();
        fieldLabel = fieldLabel.charAt(0).toUpperCase() + fieldLabel.slice(1);

        const key = parentFieldName == null ? index : `${parentFieldName}_${index}`;
        const fullFieldName = parentFieldName == null ? fieldName : `${parentFieldName}.${fieldName}`;

        if (!customHandled(fields, fullFieldName, fieldList, index)) {
            const validate = getFieldValidation(fields, fieldName);

            if (fields[fieldName].type === 'String' && fields[fieldName].enum !== undefined) {
                fieldList.push(<SelectInput key={key} source={fullFieldName} label={fieldLabel} choices={transformInChoices(fields[fieldName].enum)} />)
            } else if (fields[fieldName].type === 'String') {
                fieldList.push(<TextInput key={key} source={fullFieldName} label={fieldLabel} validate={validate} />)
            } else if (fields[fieldName].type === 'Number') {
                fieldList.push(<NumberInput key={key} source={fullFieldName} label={fieldLabel} validate={validate} />)
            } else if (fields[fieldName].type === 'Date') {
                // fieldList.push(<DateInput source={fieldName} format={dateUtils.dateFormatter} parse={dateUtils.dateParser} />)
                fieldList.push(<DateInput key={key} source={fullFieldName} label={fieldLabel} validate={validate} />)
                // fieldList.push(<DateTimeInput source={fieldName} label={fieldLabel} format={dateUtils.dateFormatter} parse={dateUtils.dateParser} />)
            } else if (fields[fieldName].type === 'Boolean') {
                fieldList.push(<BooleanInput key={key} source={fullFieldName} label={fieldLabel} validate={validate}/>)
            } else if (fields[fieldName].type === 'ObjectId') {
                const plural = getPlural(fields[fieldName].ref)
                fieldList.push(
                    <ReferenceInput key={key} label={fieldLabel} source={fullFieldName} reference={plural} allowEmpty>
                        <AutocompleteInput optionText={displayFieldForPlural(plural)} />
                    </ReferenceInput>
                );
            } else if (fields[fieldName].type === 'Object') {
                if (fields[fieldName].children) {
                    const { required, ...fieldsToProcess } = fields[fieldName].children;
                    const generatedFields = generateFromFields(fieldsToProcess, customHandled, fullFieldName);
                    fieldList.push(...generatedFields);
                }
            } else if (Array.isArray(fields[fieldName].type)) {

                if (fields[fieldName].type[0].type === 'ObjectId') {
                    const plural = getPlural(fields[fieldName].type[0].ref)

                    fieldList.push(
                        <ReferenceArrayInput key={key} label={fieldLabel} source={fullFieldName} reference={plural} allowEmpty>
                            <AutocompleteArrayInput optionText={displayFieldForPlural(plural)} />
                        </ReferenceArrayInput>
                    );
                } else {
                    let subObject;
                    if ((fields[fieldName].type[0].children)) {
                        subObject = generateFromFields(fields[fieldName].type[0].children, customHandled)

                        fieldList.push(
                            <ArrayInput key={key} source={fullFieldName} fullWidth validate={validate} sx={{ mb: 3 }}>
                                <SimpleFormIterator sx={{ ml: 2, mb: 2 }} fullWidth>
                                    {subObject}
                                </SimpleFormIterator>
                            </ArrayInput>
                        );
                    } else {
                        subObject = generateFromFields({ '': fields[fieldName].type[0] }, customHandled)

                        const children = Array.isArray(subObject)
                            ? subObject[0]
                            : subObject;

                        fieldList.push(
                            <ArrayInput key={key} source={fullFieldName} sx={{ mb: 3 }}>
                                <SimpleFormIterator>
                                    {children}
                                </SimpleFormIterator>
                            </ArrayInput>
                        );
                    }
                }
            }
        }

        index += 1;
    }

    return fieldList
};

export const getCustomTableEdit = (resource, props, customHandled, options = {}) => (
    getFieldsForRessource(resource).then((fields) => {
        const fieldList = generateFromFields(fields, customHandled);
        const { formProps = {}, isCreate = false, extraFields = [] } = options;

        if (extraFields.length > 0) {
            fieldList.push(...extraFields);
        }

        if (isCreate) {
            return (
                <Create {...props}>
                    <SimpleForm {...formProps}>
                        {fieldList}
                    </SimpleForm>
                </Create>
            );
        }
        
        return (
            <Edit {...props}>
                <SimpleForm {...formProps}>
                    {fieldList}
                </SimpleForm>
            </Edit>
        );
    })
);

export const getFieldValidation = (fields, fieldName) => {
    const field = get(fields, fieldName);

    const validation = [];

    if (field == null) {
        return validation;
    }

    if (field.required) {
        validation.push(required());
    }

    if (field.maxLength != null) {
        validation.push(maxLength(field.maxLength));
    }

    if (field.match != null) {
        validation.push(regex(new RegExp(field.match), 'Invalid value'));
    }

    return validation;
};
