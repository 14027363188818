import {Create, ReferenceInput, SimpleForm, TextInput, TopToolbar, ListButton} from 'react-admin';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const SubdomainCreateActions = ({ basePath, data, resource }) => (
    <TopToolbar>
        <ListButton basePath={basePath} label="Back" icon={<ChevronLeftIcon />} />
    </TopToolbar>
);


const SubdomainCreate = () => (
    <Create redirect="list" actions={<SubdomainCreateActions />}>
        <SimpleForm>
            <TextInput source="name" label="Subdomain Name" />
            <ReferenceInput source="domain" label="Domain" reference="domains" sort={{field:'name', order:'ASC'}} perPage={1000} />
        </SimpleForm>
    </Create>
);

export default SubdomainCreate;