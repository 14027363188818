import {
    CheckboxGroupInput,
    Datagrid,
    DateField,
    EmailField, FunctionField,
    List, ReferenceArrayField, SingleFieldList,
    TextField
} from 'react-admin';
import {FormTypes} from './FormOrientationEnums';
import Chip from '@mui/material/Chip';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const FormOrientationList = () => {
    const filters = [
        <CheckboxGroupInput alwaysOn key='formType' source='formType' label='Type de formulaire' choices={
            Object.values(FormTypes.values).map((value) => ({ id: value, name: value }))
        } />,
    ];

    return (
        <List filters={filters} sort={{field: 'createdAt', order: 'DESC'}}>
            <Datagrid rowClick="show">
                <DateField source="createdAt" label="Date"/>
                <TextField source="formType" label="Type de formulaire"/>
                <TextField source="lastname"/>
                <TextField source="firstname"/>
                <EmailField source="email"/>
                <TextField source="phone"/>
                <ReferenceArrayField source="contactedTrainers" reference="trainers" label="Formateurs contactés" emptyText="-">
                    <SingleFieldList>
                        <Chip icon={<AccountCircleIcon />} sx={{mt:0.5, mr:1}} label={
                            <FunctionField label="trainer" render={record => `${record.firstname} ${record.lastname}`} />
                        } />
                    </SingleFieldList>
                </ReferenceArrayField>
            </Datagrid>
        </List>
    );
}

export default FormOrientationList;