import PropTypes from 'prop-types';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import { useEffect, useState } from 'react';
import { CheckboxGroupInput, ReferenceField, SaveButton, SelectInput, TextField, Toolbar, useRecordContext, useResourceContext } from 'react-admin';
import CustomFileField from '../components/CustomFileField';
import { CustomFileInput } from '../components/CustomFileInput';
import { getCustomTableEdit, getFieldValidation } from '../components/CustomTableEdit';
import LocalisationInput from '../components/LocalisationInput';
import QualiopiCategories from './qualiopiCategories';
import TrainerCompanyFunctions from './trainerCompanyFunctions';

const createFileInput = (fieldName, index, filetype, multiple = false) => (
    <CustomFileInput filetype={filetype} accept='.jpg,.jpeg,.png,.pdf' maxSize={5e6}
        key={`${fieldName}_${index}`} source={fieldName} multiple={multiple}
        style={{ marginBottom: '1em' }}>
        <CustomFileField  />
    </CustomFileInput>
);

const CertificateDocumentOptions = Object.freeze({ QUALIOPI: 'qualiopi', ATTESTATION: 'attestation' });

const CertificateDocumentsFormSelect = (props) => {
    const { index, onValueChange } = props;
    const record = useRecordContext(props);

    const hasQualiopiDoc = record == null ? null : record?.certificationDocuments.qualiopi != null;

    const [value, setValue] = useState(hasQualiopiDoc ? CertificateDocumentOptions.QUALIOPI : CertificateDocumentOptions.ATTESTATION);

    const handleChange = (event) => {
        const { value } = event.target;
        setValue(value);
        onValueChange(value);
    }

    const input = value === CertificateDocumentOptions.QUALIOPI
        ? createFileInput('certificationDocuments.qualiopi', index, 'trainer-admin/certification-documents/qualiopi')
        : createFileInput('certificationDocuments.attestationSignedByPartner', index, 'trainer-admin/certification-documents/attestation-signed-by-partner');

    return (
        <Stack direction='row' sx={{ my: 2 }}>
            <RadioGroup
                name='certificationDocumentsSelection'
                value={value}
                onChange={handleChange}
            >
                <FormControlLabel value={CertificateDocumentOptions.QUALIOPI} control={<Radio />} label='Certificat Qualiopi' />
                <FormControlLabel value={CertificateDocumentOptions.ATTESTATION} control={<Radio />} label='Attestation de partenariat signée par un partenaire OF qualiopi' />
            </RadioGroup>
            {input}
        </Stack>
    );
};

CertificateDocumentsFormSelect.propTypes = {
    index: PropTypes.number,
    onValueChange: PropTypes.func
}

const unsupportedFields = ['createdAt', 'updatedAt', 'isSubmitted', 'isComplete'];

const createHandler = (handleCertificateDocChange, handleCompanyDocChange) => (
    (fields, fieldName, fieldList, index) => {
        if (unsupportedFields.includes(fieldName)) {
            return true;
        }

        if (fieldName === 'localisation') {
            const validate = getFieldValidation(fields, fieldName);

            fieldList.push(
                <LocalisationInput source={fieldName} key={index} label='Localisation' validate={validate} />
            );

            return true;
        }

        if (fieldName === 'function') {
            const validate = getFieldValidation(fields, fieldName);

            fieldList.push(
                <SelectInput key={index} source='function' validate={validate} choices={
                    Object.values(TrainerCompanyFunctions.values).map((x) => ({ id: x, name: TrainerCompanyFunctions.translate(x) }))
                } />
            );

            return true;
        }

        if (fieldName === 'qualiopiActionCategory') {
            const validate = getFieldValidation(fields, fieldName);

            fieldList.push(
                <CheckboxGroupInput key={index} source={fieldName} validate={validate} choices={
                    Object.values(QualiopiCategories.values).map((x) => ({ id: x, name: QualiopiCategories.translate(x) }))
                } />
            );

            return true;
        }

        if (fieldName === 'trainer') {
            fieldList.push(
                <ReferenceField key={index} reference='trainers' source={fieldName} sx={{ mb: 2, fontSize: '120%'  }} link='show'>
                    <div>Admin data of <TextField sx={{ fontSize: '100%' }} source='name' /></div>
                </ReferenceField>
            );

            return true;
        }

        if (fieldName === 'teacherDocuments.diplomas') {
            fieldList.push(
                createFileInput(fieldName, index, 'trainer-admin/teacher-documents/diplomas', true)
            );

            return true;
        }

        if (fieldName === 'teacherDocuments.certifications') {
            fieldList.push(
                createFileInput(fieldName, index, 'trainer-admin/teacher-documents/certifications', true)
            );

            return true;
        }

        if (fieldName === 'teacherDocuments.resume') {
            fieldList.push(
                createFileInput(fieldName, index, 'trainer-admin/teacher-documents/resume')
            );

            return true;
        }

        if (fieldName === 'companyDocuments.kbis') {
            fieldList.push(
                createFileInput(fieldName, index, 'trainer-admin/company-documents/kbis')
            );

            return true;
        }

        if (fieldName === 'companyDocuments.assuranceRcPro') {
            fieldList.push(
                createFileInput(fieldName, index, 'trainer-admin/company-documents/assurance-rc-pro')
            );

            return true;
        }

        if (fieldName === 'companyDocuments.urssaf') {
            fieldList.push(
                createFileInput(fieldName, index, 'trainer-admin/company-documents/urssaf')
            );

            return true;
        }

        if (fieldName === 'companyDocuments.attestationSignedByEmployer') {
            fieldList.push(
                createFileInput('companyDocuments.attestationSignedByEmployer', index, 'trainer-admin/company-documents/attestation-signed-by-employer')
            );

            return true;
        }

        if (fieldName === 'certificationDocuments.declarationActivityOfTrainingPartner') {
            fieldList.push(
                createFileInput(fieldName, index, 'trainer-admin/certification-documents/declaration-activity')
            );

            return true;
        }

        if (fieldName === 'certificationDocuments.qualiopi') {
            fieldList.push(
                <CertificateDocumentsFormSelect key={`certificationDocuments_${index}`} index={index} onValueChange={handleCertificateDocChange} />
            );

            return true;
        }

        if (fieldName === 'certificationDocuments.attestationSignedByPartner') {
            return true;
        }

        if (fieldName === 'otherFiles') {
            fieldList.push(
                createFileInput(fieldName, index, 'trainer-admin/other-files', true)
            );

            return true;
        }

        return false;
    }
);

const TrainerAdminEdit = (props) => {
    const resource = useResourceContext(props);

    const [data, setData] = useState();
    const [certificateDocValue, setCertificateDocValue] = useState();
    const [companyDocValue, setCompanyDocValue] = useState();

    useEffect(() => {
        const propsToUse = {
            redirect: (resource, id, data) => `trainers/${data.trainer}/show`
        };

        const formProps = {
            toolbar: (
                <Toolbar>
                    <SaveButton type='button' transform={(data) => {
                        if (certificateDocValue === CertificateDocumentOptions.QUALIOPI) {
                            data.certificationDocuments.attestationSignedByPartner = null;
                        } else {
                            data.certificationDocuments.qualiopi = null
                        }

                        return data;
                    }} />
                </Toolbar>
            )
        };

        const customHandler = createHandler(
            (value) => setCertificateDocValue(value),
            (value) => setCompanyDocValue(value)
        );

        getCustomTableEdit(resource, propsToUse, customHandler, { formProps }).then((data) => setData(data));
    }, [ props, resource, certificateDocValue, companyDocValue ]);

    if (data == null) {
        return <div></div>;
    }

    return data;
};

export default TrainerAdminEdit;
