import {Datagrid, List, ReferenceField, TextField, ArrayField, SingleFieldList, ChipField} from 'react-admin';
import Chip from '@mui/material/Chip';

const CustomChipField = ({ record = {} }) => {
    return <Chip label={`${record.question}:${record.answer}`} sx={{mr:0.5, mb:0.5}} style={{backgroundColor: 'lightcoral'}} />;
};

const WishChipField = ({ record = {} }) => {
    return <Chip label={`${record}`.substring(0,20)} sx={{mr:0.5, mb:0.5}} style={{backgroundColor: 'lightgreen'}} />;
};

const TrainerOrientationList = () => (
    <List>
        <Datagrid rowClick="edit">
            <ReferenceField source="trainer" reference="trainers" label="Formateur" />
            <TextField source="formType" label="Proposé pour les formulaires de type..." />
            <ArrayField source="exclusion" label="Écrémage">
                <SingleFieldList>
                    <CustomChipField />
                </SingleFieldList>
            </ArrayField>
            <ArrayField source="wish" label="Qualification">
                <SingleFieldList>
                    <WishChipField />
                </SingleFieldList>
            </ArrayField>
        </Datagrid>
    </List>
);

export default TrainerOrientationList;