import { useEffect, useState } from 'react';
import { TextInput, useResourceContext } from 'react-admin';
import { getCustomTableEdit, getFieldValidation } from '../components/CustomTableEdit';

const nonEditableFields = ['createdAt', 'updatedAt'];

const dataHandler = (fields, fieldName, fieldList, index) => {
    if (nonEditableFields.includes(fieldName)) {
        return true;
    }

    if (fieldName === 'password') {
        const validate = getFieldValidation(fields, fieldName);
        fieldList.push(<TextInput key={index} source={fieldName} type='password' validate={validate} />)
        return true;
    }

    return false;
};

const UserCreate = (props) => {
    const resource = useResourceContext(props);
    const [data, setData] = useState();

    useEffect(() => {
        getCustomTableEdit(resource, props, dataHandler, { isCreate: true }).then((data) => setData(data));
    }, [ props, resource ]);

    if (data == null) {
        return <div></div>;
    }

    return data;
};

export default UserCreate;
