import Fab from '@mui/material/Fab';
import { useDataProvider, useRecordContext } from 'react-admin';

const ImpersonateButton = () => {

    const dataProvider = useDataProvider();
    const trainer = useRecordContext();

    const impersonateTrainer = () => {
        dataProvider.createImpersonation({ trainer: trainer.id }).then((result) => {
            if (result.success) {
                window.open(`${process.env.REACT_APP_FRONTEND_URL}/auth/impersonate?token=${result.impersonation?.token}`);
            } else {
                alert('Une erreur est survenue');
            }
        });
    };

    return (
        <>
            <Fab variant='extended' color='primary' sx={{ position: 'fixed', bottom: 20, right: 50 }}
                onClick={ () => impersonateTrainer()}>
                <span style={{ fontSize: '1.8em'}}>🎭</span>
            </Fab>
        </>
    );
}

export default ImpersonateButton;