import Box from '@mui/material/Box'
import Card from '@mui/material/Card';
import { SaveButton, SimpleForm, Title, Toolbar, required, useNotify } from 'react-admin';
import { useDataProvider } from 'react-admin';
import { useEffect, useState } from 'react';
import PriceInput from '../components/PriceInput';

const LicenseCost = () => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const [cost, setCost] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        dataProvider.getLicenseCost()
            .then((data) => {
                console.log(data);
                setCost(data);
                setLoading(false);
            })
    }, [
        dataProvider
    ]);

    function submit(data) {
        console.log(data);
        dataProvider.updateLicenseCost({ data })
            .then(() => {
                setCost(data);
                notify('License cost updated', { autoHideDuration: 4000 });
            });
    }

    if (loading) {
        return <></>;
    }

    const toolbar = (
        <Toolbar>
            <SaveButton alwaysEnable />
        </Toolbar>
    );

    return (
        <Card sx={{ mt: 2 }}>
            <Title title='Subscription price' />
            <SimpleForm record={cost} toolbar={toolbar} onSubmit={submit}>
                <Box display={{ xs: 'block', sm: 'flex' }}>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                        <PriceInput source='discount.monthly' label='Special monthly price (within 30 days)' validate={required()} />
                    </Box>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                        <PriceInput source='default.monthly' label='Monthly price' validate={required()} />
                    </Box>
                </Box>
                <Box display={{ xs: 'block', sm: 'flex' }}>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                        <PriceInput source='discount.yearly' label='Special annual price (within 30 days)' validate={required()} />
                    </Box>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                        <PriceInput source='default.yearly' label='Annual price' validate={required()} />
                    </Box>
                </Box>
            </SimpleForm>
        </Card>
    );
};

export default LicenseCost;
