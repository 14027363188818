import {Datagrid, DateField, EmailField, List, ReferenceField, TextField} from 'react-admin';
import RfiStatusField from '../components/RfiStatusField';

const RfiList = () => (
    <List sort={{ field: 'createdAt', order: 'DESC' }}>
        <Datagrid rowClick="show">
            <RfiStatusField />
            <ReferenceField source="toTrainer" reference="trainers" link="show" />
            <TextField source="fromFirstname" />
            <TextField source="fromLastname" />
            <EmailField source="fromEmail" />
            <DateField source="createdAt" />
            <DateField source="viewedAt" />
        </Datagrid>
    </List>
);

export default RfiList;