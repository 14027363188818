const TrainerCompanyFunctions = Object.freeze({
    values: Object.freeze({
        FOUNDER: 'founder',
        EMPLOYEE: 'employee',
        PARTNER: 'partner',
    }),
    translate(value) {
        if (value == null) {
            return value;
        }

        switch (value) {
        case this.values.FOUNDER:
            return 'Fondateur';
        case this.values.EMPLOYEE:
            return 'Salarié';
        case this.values.PARTNER:
            return 'Partenaire';
        default:
            throw new RangeError(`Invalid value '${value}'`);
        }
    }
});

export default TrainerCompanyFunctions;
