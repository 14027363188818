import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import PublicIcon from '@mui/icons-material/Public';
import { styled } from '@mui/material/styles';
import { Link } from '@mui/material';
import {
    EditButton,
    EmailField,
    FunctionField,
    Labeled,
    ListButton,
    ReferenceField,
    ReferenceManyField,
    ReferenceOneField,
    Show,
    TextField,
    TopToolbar,
    useRecordContext,
    useShowContext
} from 'react-admin';
import {getFileUri, getTrainerProfileUri} from '../helpers';
import TrainerAdmin from './TrainerAdmin';
import TrainerProfile from './TrainerProfile';
import CustomDateField from '../components/CustomDateField';
import TrainerLicenseDetails from '../components/TrainerLicenseDetails';
import TrainerStatusField from '../components/TrainerStatusField';
import TrainerValidationButton from '../components/TrainerValidationButton';
import ImpersonateButton from '../components/ImpersonateButton';
import TrainerFeedback from './TrainerFeedback';
import TrainerTrainings from './TrainerTrainings';
import TrainerTagField from '../components/TrainerTag';


const TrainerDetail = styled('div')(({ theme }) => ({
    fontSize: '85%',
    color: theme.palette.grey['600'],
    marginBottom: 1
}));

const Disabled = () => {
    const record = useRecordContext();

    if (record == null || record.isDisabled !== true) {
        return;
    }

    return <Chip label='Disabled' size='small' color='error' />;
};

const TrainerShow = () => {
    const PostShowActions = () => (
        <TopToolbar>
            <ListButton />
            <EditButton />
        </TopToolbar>
    );

    return (
        <Show actions={<PostShowActions />}>
            <Box sx={{ margin: '20px 10px' }}>
                <Stack gap={3} divider={<Divider />}>
                    <Stack direction='row' justifyContent='space-between'>
                        <Stack direction='row' gap={5} alignItems='center'>
                            <FunctionField
                                render={(record) => <Avatar sx={{ height: 100, width: 100, boxShadow: '0 2px 5px gray' }} src={getFileUri(record.picture, 'trainer/picture')} />}
                            />
                            <Stack>
                                <TextField source='name' sx={{ fontSize: '150%', fontWeight: 'bold' }} />
                                <TrainerDetail>
                                    <EmailOutlinedIcon fontSize='inherit' sx={{ verticalAlign: 'middle', marginInlineEnd: .5 }} />
                                    <EmailField source='email' />
                                </TrainerDetail>
                                <TrainerDetail>
                                    <PhoneOutlinedIcon fontSize='inherit' sx={{ verticalAlign: 'middle', marginInlineEnd: .5 }} />
                                    <TextField source='phone' />
                                </TrainerDetail>
                                <TrainerDetail>
                                    <PublicIcon fontSize='inherit' sx={{ verticalAlign: 'middle', marginInlineEnd: .5 }} />
                                    <FunctionField
                                        render={(record) => {
                                            if (record.slug == null) {
                                                return '-';
                                            } else {
                                                const uri = getTrainerProfileUri(record.slug);
                                                return (
                                                    <Link href={uri} target="_blank">{uri}</Link>
                                                );
                                            }
                                        }}
                                    />
                                </TrainerDetail>
                            </Stack>
                        </Stack>

                        <TrainerStatusField sx={{ fontSize: '120%' }} />
                    </Stack>

                    <Stack direction='row' gap={5}>
                        <Labeled>
                            <CustomDateField source='createdAt' />
                        </Labeled>

                        <Labeled>
                            <CustomDateField source='updatedAt' emptyText='-' />
                        </Labeled>

                        <Labeled>
                            <CustomDateField source='submittedAt' emptyText='-' />
                        </Labeled>

                        <Labeled>
                            <CustomDateField source='validatedAt' emptyText='-' />
                        </Labeled>

                        <Labeled>
                            <TextField source='sponsorshipCode' />
                        </Labeled>

                        <Labeled>
                            <ReferenceField source='sponsoredBy' reference='trainers' emptyText='-' link='show'>
                                <>
                                    <TextField source='name' />
                                    &nbsp;
                                    (<TextField source='sponsorshipCode' />)
                                </>
                            </ReferenceField>
                        </Labeled>

                        <Labeled>
                            <CustomDateField source='validCguAt' emptyText='-' />
                        </Labeled>

                        <Labeled label='License'>
                            <TrainerLicenseDetails />
                        </Labeled>

                        <Labeled>
                            <TrainerTagField source='tag' size='small' />
                        </Labeled>
                    </Stack>
                    
                    <ReferenceOneField reference="trainer-profiles" target="trainer">
                        <TrainerProfile />
                    </ReferenceOneField>
                    
                    <ReferenceOneField reference="trainer-admins" target="trainer">
                        <TrainerAdmin />
                    </ReferenceOneField>

                    <ReferenceManyField reference='trainings' target='trainer'>
                        <TrainerTrainings />
                    </ReferenceManyField>

                    <ReferenceManyField reference='feedback' target='trainer' perPage={3} sort={{ field: 'createdAt', order: 'DESC' }}>
                        <TrainerFeedback />
                    </ReferenceManyField>
                </Stack>
            </Box>
            <TrainerValidationButton />
            <ImpersonateButton />
        </Show>
    );
};

export default TrainerShow;
