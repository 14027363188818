import dataProvider from './dataProvider';

export const getFieldsNameForRessource = async field => {

    return dataProvider.getSchema(field).then((result) => {
        if (result.data) {
            var fieldsToReturn = [];
            for (const field in result.data) {
                fieldsToReturn.push(field)
            }
            return fieldsToReturn;
        }
        return [ 'id' ]
    })
}

export const getFieldsForRessource = async field => {

    return dataProvider.getSchema(field).then((result) => {
        if (result.data) {
            return result.data;
        }
        return [ 'id' ]
    })
}
