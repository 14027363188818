import {
    Login,
    LoginForm
} from 'react-admin';

const LoginPage = () => {
    return <Login>
        <>
            <LoginForm />
        </>
    </Login>;
};

export default LoginPage;
