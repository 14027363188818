import {
    Datagrid,
    List,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    TextField
} from 'react-admin';

const SubdomainList = () => {
    const postFilters = [
        <ReferenceInput key='domain' label='Domain' source='domain' reference='domains' sort={{field:'name', order:'ASC'}} perPage={1000} alwaysOn>
            <SelectInput optionText='name' />
        </ReferenceInput>
    ];
    return (
        <List filters={postFilters} sort={{ field: 'name', order: 'ASC' }}>
            <Datagrid rowClick="edit">
                <TextField source="name" label="SubDomain" />
                <ReferenceField source="domain" label="Domain" reference="domains" />
            </Datagrid>
        </List>
    );
}

export default SubdomainList;