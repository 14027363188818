import {
    Show,
    SimpleShowLayout,
    TextField,
    NumberField,
    BooleanField,
    ReferenceField,
    ReferenceArrayField
} from 'react-admin';
import { getFieldsForRessource } from '../fieldsForResource';
import { getPlural, displayFieldForPlural } from '../utils/utils';
import BulletList from './BulletList';
import BulletListField from './BulletListField';
import CustomDateField from './CustomDateField';

const defaultCustomHandled = () => false;

const generateFromFields = (fields, customHandledFunc) => {
    const customHandled = customHandledFunc ?? defaultCustomHandled;
    const fieldList = []
    let index = 0;

    for (const fieldName in fields) {
        if (!customHandled(fields, fieldName, fieldList, index)) {
            const type = fields[fieldName].type;

            if (Array.isArray(type)) {
                if (fields[fieldName].type[0].type === 'ObjectId') {
                    const plural = getPlural(fields[fieldName].type[0].ref)

                    fieldList.push(
                        <ReferenceArrayField key={index} source={fieldName} reference={plural}>
                            <BulletList source={displayFieldForPlural(plural)} />
                        </ReferenceArrayField>
                    );
                } else {
                    fieldList.push(
                        <BulletListField key={index} source={fieldName} />
                    );
                }
            } else {
                switch (type) {
                case 'String':
                    fieldList.push(<TextField key={index} source={fieldName}/>)
                    break;
                case 'Number':
                    fieldList.push(<NumberField key={index} source={fieldName}/>)
                    break;
                case 'Date':
                    fieldList.push(<CustomDateField key={index} source={fieldName} />)
                    break;
                case 'Boolean':
                    fieldList.push(<BooleanField key={index} source={fieldName} />)
                    break;
                case 'ObjectId':
                    const plural = getPlural(fields[fieldName].ref);
                    fieldList.push(
                        <ReferenceField key={index} source={fieldName} reference={plural} link='show'>
                            <TextField source={displayFieldForPlural(plural)} />
                        </ReferenceField>
                    );
                    break;
                default:
                    break;
                }
            }
        }

        index += 1;
    };
    return fieldList
}

export const getCustomTableShow = (resource, props, customHandled, options) => (
    getFieldsForRessource(resource).then((fields) => {
        const fieldList = generateFromFields(fields, customHandled);

        if (options?.extraFields != null) {
            fieldList.push(options.extraFields);
        }

        if (options?.onlyFields === true) {
            return fieldList;
        }

        return (
            <Show {...props}>
                <SimpleShowLayout>
                    {fieldList}
                </SimpleShowLayout>
            </Show>
        );
    })
);
