import {DateField, EmailField, ReferenceField, Show, SimpleShowLayout, TextField} from 'react-admin';

const FormContactShow = () => (
    <Show>
        <SimpleShowLayout>
            <DateField source="createdAt" showTime="true" label="Date de la demande" />
            <TextField source="firstname" label="Prénom" />
            <TextField source="lastname" label="Nom" />
            <EmailField source="email" />
            <TextField source="message" />
            <ReferenceField source="student" reference="students" emptyText="Utilisateur non connecté" label="Apprenant" />
        </SimpleShowLayout>
    </Show>
);

export default FormContactShow;