import { useEffect, useState } from 'react';
import { EditButton, ListButton, TopToolbar, useResourceContext } from 'react-admin';
import CustomDateField from '../components/CustomDateField';
import { getCustomTableShow } from '../components/CustomTableShow';

const dataHandler = (fields, fieldName, fieldList, index) => {
    if (fieldName === 'startDate' || fieldName === 'endDate') {
        fieldList.push(
            <CustomDateField key={index} source={fieldName} options={{ year: 'numeric', month: 'numeric', day: 'numeric'}} />
        );

        return true;
    }

    return false;
};

const PostShowActions = () => (
    <TopToolbar>
        <ListButton />
        <EditButton />
    </TopToolbar>
);

const AnnouncementShow = (props) => {
    const resource = useResourceContext(props);
    const [data, setData] = useState();

    useEffect(() => {
        const propsToUse = {
            ...props,
            actions: <PostShowActions />
        };

        getCustomTableShow(resource, propsToUse, dataHandler).then((data) => setData(data));
    }, [ props, resource ]);

    if (data == null) {
        return <div></div>;
    }

    return data;
};

export default AnnouncementShow;
