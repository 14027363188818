import {Create, ReferenceInput, SimpleForm, TextInput} from 'react-admin';

const TrainerOrientationCreate = () => (
    <Create redirect="edit">
        <SimpleForm>
            <ReferenceInput source="trainer" reference="trainers" label="Formateur" filter={{ status: 'validated' }} sort={{field:'lastname', order:'ASC'}} perPage={1000} />
            <TextInput source="formType" label="Type de formulaire" defaultValue="bilan" />
        </SimpleForm>
    </Create>
);

export default TrainerOrientationCreate;