const BLACK = '#172D5C';
const WHITE = '#FFFFFF';

const tags = new Map([
    ['None', { color: BLACK, backgroundColor: '#EEEEEE' }],
    ['Trial', { color: '#046CF2', backgroundColor: '#E8F4FF' }],
    ['Top100', { color: WHITE, backgroundColor: BLACK }],
    ['Launch', { color: BLACK, backgroundColor: '#A5EB8C' }],
    ['Coupon', { color: WHITE, backgroundColor: '#459B6D' }],
    ['Regular', { color: WHITE, backgroundColor: '#046CF2' }],
    ['Expired Trial', { color: '#F5836A', backgroundColor: '#FFEDED' }],
    ['Expired', { color: WHITE, backgroundColor: '#F5836A' }]
]);

export const TrainerTags = {
    get tags() {
        return Array.from(tags.entries()).map(([key, value]) => ({ name: key, ...value }));
    },
    get tagNames() {
        return Array.from(tags.keys());
    },
    getColors(tagName) {
        if (tags.has(tagName)) {
            return tags.get(tagName);
        }

        return { color: 'inherit', backgroundColor: 'inherit' };
    }
};
