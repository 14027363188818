import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import { useRecordContext } from 'react-admin';
import { formatDate } from '../utils/utils';

const TrainerLicenseField = (props) => {
    const trainer = useRecordContext();

    if (trainer?.hasLicense === true) {
        return (
            <Tooltip title={`From ${formatDate(trainer.licenseStartDate)}`}>
                <Chip label='Active' color='info' {...props} />
            </Tooltip>
        );
    }

    return <Chip label='Inactive' color='default' {...props} />;
};

export default TrainerLicenseField;
