import { useEffect, useState } from 'react';
import { CheckboxGroupInput, RadioButtonGroupInput, SelectInput, TextInput, useResourceContext } from 'react-admin';
import { getCustomTableEdit, getFieldValidation } from '../components/CustomTableEdit';
import { TrainingFormats } from '../trainings/trainingEnums';
import { StudentBudget, StudentExperience, StudentStatus, StudentTrainingType } from './studentEnums';

const nonEditableFields = ['createdAt', 'updatedAt', 'deletedAt', 'lastLoginAt'];

const dataHandler = (fields, fieldName, fieldList, index) => {
    if (nonEditableFields.includes(fieldName)) {
        return true;
    }

    if (fieldName === 'password') {
        fieldList.push(<TextInput key={index} source={fieldName} type='password' />)
        return true;
    }

    if (fieldName === 'status') {
        const validate = getFieldValidation(fields, fieldName);

        fieldList.push(
            <SelectInput key={index} source={fieldName} validate={validate} choices={
                Object.values(StudentStatus.values).map((value) => ({ id: value, name: StudentStatus.translate(value) }))
            } />
        );

        return true;
    }

    if (fieldName === 'trainingType') {
        const validate = getFieldValidation(fields, fieldName);

        fieldList.push(
            <RadioButtonGroupInput key={index} source={fieldName} validate={validate} choices={
                Object.values(StudentTrainingType.values).map((value) => ({ id: value, name: StudentTrainingType.translate(value) }))
            } />
        );

        return true;
    }

    if (fieldName === 'yearlyBudget') {
        const validate = getFieldValidation(fields, fieldName);

        fieldList.push(
            <SelectInput key={index} source={fieldName} validate={validate} choices={
                Object.values(StudentBudget.values).map((value) => ({ id: value, name: StudentBudget.translate(value) }))
            } />
        );

        return true;
    }

    if (fieldName === 'experience') {
        const validate = getFieldValidation(fields, fieldName);

        fieldList.push(
            <RadioButtonGroupInput key={index} source={fieldName} validate={validate} choices={
                Object.values(StudentExperience.values).map((value) => ({ id: value, name: StudentExperience.translate(value) }))
            } />
        );

        return true;
    }

    if (fieldName === 'preferredFormat') {
        const validate = getFieldValidation(fields, fieldName);

        fieldList.push(
            <CheckboxGroupInput key={index} source={fieldName} validate={validate} choices={
                Object.values(TrainingFormats.values).map((value) => ({ id: value, name: TrainingFormats.translate(value) }))
            } />
        );

        return true;
    }

    return false;
};

const StudentEdit = (props) => {
    const resource = useResourceContext(props);
    const [data, setData] = useState();

    useEffect(() => {
        getCustomTableEdit(resource, props, dataHandler).then((data) => setData(data));
    }, [ props, resource ]);

    if (data == null) {
        return <div></div>;
    }

    return data;
};

export default StudentEdit;
