export const StudentBudget = Object.freeze({
    values: Object.freeze({
        LESS_THAN_10K: 'less_than_10k',
        _10K_TO_20K: '10k_to_20k',
        _20K_TO_50K: '20k_to_50k',
        _50K_TO_100K: '50k_to_100k',
        MORE_THAN_100K: 'more_than_100k',
    }),
    translate(value) {
        if (value == null) {
            return value;
        }

        switch (value) {
        case this.values.LESS_THAN_10K:
            return '< de 10k EUR';
        case this.values._10K_TO_20K:
            return 'Entre 10K et 20K EUR';
        case this.values._20K_TO_50K:
            return 'Entre 20K et 50K EUR';
        case this.values._50K_TO_100K:
            return 'Entre 50K et 100K EUR';
        case this.values.MORE_THAN_100K:
            return '> à 100K EUR';
        default:
            throw new RangeError(`Invalid value '${value}'`);
        }
    }
});

export const StudentExperience = Object.freeze({
    values: Object.freeze({
        JUNIOR: 'junior',
        INTERMEDIATE: 'intermediate',
        SENIOR: 'senior',
    }),
    translate(value) {
        if (value == null) {
            return value;
        }

        switch (value) {
        case this.values.JUNIOR:
            return '1 à 5 ans (Junior)';
        case this.values.INTERMEDIATE:
            return '6 à 10 ans (Sénior)';
        case this.values.SENIOR:
            return '10 ans et + (Expert)';
        default:
            throw new RangeError(`Invalid value '${value}'`);
        }
    }
});

export const StudentTrainingType = Object.freeze({
    values: Object.freeze({
        VAE: 'vae',
        TRAINING: 'training',
        COMPETENCES: 'competences',
    }),
    translate(value) {
        if (value == null) {
            return value;
        }

        switch (value) {
        case this.values.VAE:
            return 'VAE';
        case this.values.TRAINING:
            return 'Formation';
        case this.values.COMPETENCES:
            return 'Bilan de compétences';
        default:
            throw new RangeError(`Invalid value '${value}'`);
        }
    }
});

export const StudentStatus = Object.freeze({
    values: Object.freeze({
        INDEPENDENT: 'independent',
        EMPLOYEE: 'employee',
        LEADER: 'leader',
        UNEMPLOYED: 'unemployed',
    }),
    translate(value) {
        if (value == null) {
            return value;
        }

        switch (value) {
        case this.values.INDEPENDENT:
            return 'Indépendant';
        case this.values.EMPLOYEE:
            return 'Salarié';
        case this.values.LEADER:
            return 'Dirigeant';
        case this.values.UNEMPLOYED:
            return 'Demandeur d\' emploi';
        default:
            throw new RangeError(`Invalid value '${value}'`);
        }
    }
});
