import Typography from '@mui/material/Typography';
import { Datagrid, EditButton, EmailField, ListBase, ReferenceField, TextField } from 'react-admin';
import AddressField from '../components/AddressField';
import CustomDateField from '../components/CustomDateField';
import LicenseRequestStatusField from '../components/LicenseRequestStatusField';
import { LicenseRequestStatus } from '../license-request/licenseRequestEnums';

const DashboardLicenseRequests = () => {
    return (
        <div>
            <Typography fontSize={'120%'} fontWeight="bold">New subscriber requests</Typography>
            <ListBase
                resource='license-requests'
                filter={{ status: LicenseRequestStatus.values.NOT_TREATED }}
                sort={{ field: 'createdAt', order: 'DESC' }}
            >
                <Datagrid bulkActionButtons={false} empty={<div>No new subscriber requests.</div>}>
                    <TextField source='name' />
                    <EmailField source='email' />
                    <AddressField />
                    <TextField source='coupon' emptyText='-' />
                    <ReferenceField source='sponsoredBy' reference='trainers' emptyText='-' link='show'>
                        <>
                            <TextField source='name' />
                            &nbsp;
                            (<TextField source='sponsorshipCode' />)
                        </>
                    </ReferenceField>
                    <CustomDateField source='createdAt' />
                    <LicenseRequestStatusField />
                    <EditButton />
                </Datagrid>
            </ListBase>
        </div>
    );
};

export default DashboardLicenseRequests;
