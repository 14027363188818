// in src/MyMenu.js
import { Menu } from 'react-admin';
import SpeedRoundedIcon from '@mui/icons-material/SpeedRounded';
import HandymanIcon from '@mui/icons-material/Handyman';

export const MyMenu = () => (
    <Menu>
        <Menu.DashboardItem />
        <Menu.ResourceItem name="students" />
        <Menu.ResourceItem name="trainers" />
        <Menu.ResourceItem name="license-requests" />
        <Menu.ResourceItem name="trainings" />
        <Menu.ResourceItem name="connections" />
        <Menu.ResourceItem name="rfis" />
        <Menu.ResourceItem name="transactions" />
        <Menu.ResourceItem name="announcements" />
        <Menu.ResourceItem name="backoffice/users" />
        <Menu.ResourceItem name="form-contacts" />
        <Menu.ResourceItem name="form-orientations" />
        <Menu.Item to="/control-panels" primaryText="Control Panels" leftIcon={<SpeedRoundedIcon />} />
        <Menu.Item to="/config" primaryText="Configuration" leftIcon={<HandymanIcon />} />
    </Menu>
);
