import {Admin, CustomRoutes, Resource} from 'react-admin';
import { Route } from 'react-router-dom';
import { MyLayout } from './utils/layout';

import authProvider from './authProvider';
import dataProvider from './dataProvider';

import './App.css';
import BookIcon from '@mui/icons-material/Book';
import SchoolIcon from '@mui/icons-material/School';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import FeedbackIcon from '@mui/icons-material/Feedback';
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import StorefrontIcon from '@mui/icons-material/Storefront';
import HandshakeIcon from '@mui/icons-material/Handshake';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';


import LoginPage from './pages/LoginPage';
import Dashboard from './pages/Dashboard';
import StudentList from './student/StudentList';
import StudentEdit from './student/StudentEdit';
import TrainerList from './trainers/TrainerList';
import TrainerShow from './trainers/TrainerShow';
import StudentShow from './student/StudentShow';
import TrainerEdit from './trainers/TrainerEdit';
import TrainerProfileEdit from './trainers/TrainerProfileEdit';
import UserList from './users/UserList';
import TrainerAdminEdit from './trainers/TrainerAdminEdit';
import UserShow from './users/UserShow';
import UserEdit from './users/UserEdit';
import UserCreate from './users/UserCreate';
import AnnouncementList from './announcements/AnnouncementList';
import AnnouncementShow from './announcements/AnnouncementShow';
import AnnouncementEdit from './announcements/AnnouncementEdit';
import AnnouncementCreate from './announcements/AnnouncementCreate';
import ConnectionList from './connections/ConnectionList';
import ConnectionEdit from './connections/ConnectionEdit';
import TrainingList from './trainings/TrainingList';
import TrainingShow from './trainings/TrainingShow';
import TrainingEdit from './trainings/TrainingEdit';
import LicenseCostList from './license-price/LicenseCostList';
import LicenseRequestList from './license-request/LicenseRequestList';
import LicenseRequestEdit from './license-request/LicenseRequestEdit';
import ControlPanels from './pages/ControlPanels';
import Config from './pages/Config';
import RfiList from './rfis/RfiList';
import RfiShow from './rfis/RfiShow';
import RfiEdit from './rfis/RfiEdit';
import TransactionList from './transactions/TransactionList';
import TransactionShow from './transactions/TransactionShow';
import TransactionEdit from './transactions/TransactionEdit';
import TransactionCreate from './transactions/TransactionCreate';
import FormContactList from './form-contact/FormContactList';
import FormContactShow from './form-contact/FormContactShow';
import FormOrientationList from './form-orientation/FormOrientationList';
import FormOrientationShow from './form-orientation/FormOrientationShow';
import DomainList from './domains/DomainList';
import SubdomainList from './domains/SubdomainList';
import SubdomainCreate from './domains/SubdomainCreate';
import SubdomainEdit from './domains/SubdomainEdit';
import TrainerOrientationEdit from './trainer-orientation/TrainerOrientationEdit';
import TrainerOrientationList from './trainer-orientation/TrainerOrientationList';
import TrainerOrientationCreate from './trainer-orientation/TrainerOrientationCreate';

const App = () => <Admin
    layout={MyLayout}
    authProvider={authProvider}
    dataProvider={dataProvider}
    loginPage={LoginPage}
    dashboard={Dashboard}
    requireAuth
    disableTelemetry
>
    <Resource name='students' options={{ label: 'Students' }} list={StudentList} edit={StudentEdit} show={StudentShow} icon={SchoolIcon} recordRepresentation={(record) => `${record.firstname} ${record.lastname}`}  />
    <Resource name='trainers' options={{ label: 'Trainers' }} list={TrainerList} edit={TrainerEdit} show={TrainerShow} icon={AccountCircleIcon} recordRepresentation={(record) => `${record.firstname} ${record.lastname}`} />
    <Resource name='trainer-profiles' edit={TrainerProfileEdit} />
    <Resource name='trainer-admins' edit={TrainerAdminEdit} />
    <Resource name='trainings' options={{ label: 'Trainings' }} list={TrainingList} edit={TrainingEdit} show={TrainingShow} icon={BookIcon} recordRepresentation="title" />
    <Resource name='announcements' list={AnnouncementList} show={AnnouncementShow} edit={AnnouncementEdit} create={AnnouncementCreate} icon={FeedbackIcon} />
    <Resource name='backoffice/users' options={{ label: 'Backoffice users' }} list={UserList} show={UserShow} edit={UserEdit} create={UserCreate} icon={AdminPanelSettingsIcon} />
    <Resource name='connections' options={{ label: 'Visios' }} list={ConnectionList} edit={ConnectionEdit} icon={VideoCameraFrontIcon} />
    <Resource name='license-cost' options={{ label: 'Subscription price' }} list={LicenseCostList} icon={StorefrontIcon} />
    <Resource name='license-requests' options={{ label: 'Subscriptions' }} list={LicenseRequestList} edit={LicenseRequestEdit} icon={HandshakeIcon} />
    <Resource name='rfis' options={{ label: 'Requests for info' }} list={RfiList} show={RfiShow} edit={RfiEdit} icon={ContactMailIcon} recordRepresentation={(record) => `${record.fromFirstname} ${record.fromLastname}, le ${new Date(record.createdAt).toLocaleDateString('fr-FR')}`} />
    <Resource name='transactions' options={{ label: 'Transactions' }} list={TransactionList} edit={TransactionEdit} show={TransactionShow} create={TransactionCreate} icon={ReceiptLongIcon} />
    <Resource name='form-contacts' options={{ label: 'Form: Contacts' }} list={FormContactList} show={FormContactShow} icon={ConnectWithoutContactIcon} />
    <Resource name='form-orientations' options={{ label: 'Form: Orientation' }} list={FormOrientationList} show={FormOrientationShow} icon={ConnectWithoutContactIcon} recordRepresentation={(record) => (record.formType === 'bilan' ? 'Funnel bilan' : 'non')}  />
    <Resource name='domains' options={{ label: 'Domains' }} list={DomainList} recordRepresentation={(record) => record.name}  />
    <Resource name='subdomains' options={{ label: 'Subdomains' }} list={SubdomainList} edit={SubdomainEdit} create={SubdomainCreate} />
    <Resource name='trainer-orientations' list={TrainerOrientationList} edit={TrainerOrientationEdit} create={TrainerOrientationCreate} />
    <CustomRoutes>
        <Route path='/control-panels' element={<ControlPanels />} />
        <Route path='/config' element={<Config />} />
    </CustomRoutes>
</Admin>;

export default App;
