
import {Title} from 'react-admin';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import {Link} from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import HandymanIcon from '@mui/icons-material/Handyman';
import PendingIcon from '@mui/icons-material/Pending';

const Config = () => {


    return (
        <>
            <Card sx={{ mt: 2 }}>
                <Title title="Configuration"/>
                <CardContent>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <HandymanIcon style={{marginRight: '16px', fontSize: 32}} />
                        <div>

                            <List>
                                <ListItem>
                                    <Link href="/#/domains" underline="hover">Liste des domaines</Link>
                                </ListItem>
                                <ListItem>
                                    <Link href="/#/subdomains" underline="hover">Gérer les sous-domaines</Link>
                                </ListItem>
                                <ListItem>
                                    <Link href="/#/license-cost" underline="hover">Montants de souscription et abonnement</Link>
                                </ListItem>
                            </List>

                        </div>
                    </div>
                </CardContent>
            </Card>
            <Card sx={{ mt: 2 }}>
                <CardContent>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <PendingIcon style={{marginRight: '16px', fontSize: 32, color:'orange'}} />
                        <div>
                            <List>
                                <ListItem>
                                    En cours de construction...
                                </ListItem>
                                <ListItem>
                                    <Link href="/#/trainer-orientations" underline="hover">Association formateurs - funnels</Link>
                                </ListItem>
                            </List>
                        </div>
                    </div>
                </CardContent>
            </Card>
        </>
    );
}




export default Config;