import Chip from '@mui/material/Chip';
import {useRecordContext} from 'react-admin';

const RfiStatusField = (props) => {
    const rfi = useRecordContext();

    if (rfi?.status == null) {
        return <span></span>;
    }

    switch (rfi.status) {
    case 'new':
        return <Chip label='New' color='info' {...props} />
    case 'viewed':
        return <Chip label='Read' color='success' {...props} />
    case 'canceled':
        return <Chip label='Canceled' color='danger' {...props} />
    case 'archived':
        return <Chip label='Archived' color='default' {...props} />
    default:
        return <Chip label='Unknown' {...props} />
    }
};

export default RfiStatusField;
